import React from 'react'
import ObjectID from 'bson-objectid'
import { connect } from 'react-redux'
import {
  getSeason,
  startSeason,
  createDivisions,
  calculateStandings,
  dragPlayer,
  addDivision,
  deleteDivision,
  editSeason,
  closeSeason,
  checkSeason,
} from 'store/actions/seasons'
import theme from 'lib/theme'
import { Header, Menu, Table, Image, Label, Card, Icon, Modal, Input, Form, Message, Button as SButton, TextArea, Dimmer, Loader, Dropdown, Confirm, Container as SContainer, Grid, Checkbox } from 'semantic-ui-react'
import { withRouter, NavLink } from 'react-router-dom'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import DatePicker from '../../components/ZonedDatePicker'
import {
  Container,
  Clickable,
  Button,
  Text,
  Avatar
} from 'components'
import { addDays, addWeeks, differenceInHours, differenceInMinutes, format, setHours } from 'date-fns'
import Season from './Season'
import runGlicko from '../../store/actions/seasons/runGlicko'
import SeasonPlayersTable from '../../components/SeasonPlayersTable'
import SeasonPlayingTeamsTable from '../../components/SeasonPlayingTeamsTable'
import startLateReg from '../../store/actions/seasons/startLateReg'
import { debounce } from 'debounce'
import sendPromotional from '../../store/actions/seasons/sendPromotional'
import sendCommunication from '../../store/actions/seasons/sendCommunication'
import CourtsSelect from '../../components/common/CourtsSelect'
import DivisionPlanTable from '../../components/DivisionPlanTable'
import RichTextInput from '../../components/common/RichTextInput/RichTextInput'
import { zonedTimeToUtc } from 'date-fns-tz'
import { defaultTimezone } from '../../lib/constants'

let timer
class League extends React.Component {
  constructor(props) {
    super(props)

    const { season } = this.props

    if (season) {
      this.state = {
        count: 0,
        start: season.start && new Date(season.start),
        end: season.end && new Date(season.end),
        regStart: season.regStart && new Date(season.regStart),
        regEnd: season.regEnd && new Date(season.regEnd),
        midPoint: season.midPoint && new Date(season.midPoint),
        rules: season.rules ? season.rules.replace(/\•/g, '*') : '',
        rulesUrl: season.rulesUrl,
        info: season.info ? season.info.replace(/\•/g, '*') : '',
        infoRichText: season.infoRichText ? season.infoRichText : '',
        notes: season.notes ? season.notes.replace(/\•/g, '*') : '',
        joinAlert: season.joinAlert,
        name: season.name,
        price: season.price,
        regularWeeks: season.regularWeeks,
        courts: season.courts,
        playOffWeeks: season.playOffWeeks,
        annualPrice: season.annualPrice,
        individualPrice: season.individualPrice,
        slug: season.slug,
        modalOpen: false,
        showDivisionPlan: false,
        pushTitle: '',
        pushMessage: '',
        includeUnsubscribed: false,
        includeAnnuals: false,
        includeNewPlayersOnly: false,
        includeWithdrawn: false,
        includeSnoozed: false,
        includeAllRegistered: false,
        allowCampaignOverride: false,
      }
    } else {
      this.state = {
        count: 0
      }
    }

  }

  componentDidUpdate(prevProps, prevState) {
    const { seasonId, season } = this.props;

    if (prevProps.seasonId !== seasonId || !season.users) {
      this.fetchSeason();
    }

    if (season.users && (!prevState.fullSeason || !prevState.fullSeason.users)) {
      this.setState({
        fullSeason: season,
        start: season.start && new Date(season.start),
        end: season.end && new Date(season.end),
        regStart: season.regStart && new Date(season.regStart),
        regEnd: season.regEnd && new Date(season.regEnd),
        midPoint: season.midPoint && new Date(season.midPoint),
        rules: season.rules ? season.rules.replace(/\•/g, '*') : '',
        rulesUrl: season.rulesUrl,
        info: season.info ? season.info.replace(/\•/g, '*') : '',
        infoRichText: season.infoRichText ? season.infoRichText : '',
        notes: season.notes ? season.notes.replace(/\•/g, '*') : '',
        joinAlert: season.joinAlert,
        name: season.name,
        price: season.price,
        individualPrice: season.individualPrice,
        annualPrice: season.annualPrice,
        slug: season.slug,
        loading: false,
        pushTitle: '',
        pushMessage: '',
        includeUnsubscribed: false,
        includeAnnuals: false,
        includeWithdrawn: false,
        includeSnoozed: false,
        includeAllRegistered: false,
        includeAllSeasons: false,
        allowCampaignOverride: false,
        // initialize promo types array for react semantic ui dropdown
        promoTypes: [
          { key: 'promo1', value: 'promo1', text: '1st Promo - Email', broadcast: '12' },
          { key: 'promo2', value: 'promo2', text: '2nd Promo - Email', broadcast: '12' },
          { key: 'promo3', value: 'promo3', text: 'Deadline Promo - Email', broadcast: '25' },
          { key: 'custom', value: 'custom', text: 'Custom - Broadcast Id', broadcast: '' },
        ],
        selectedPromoType: 'promo1',
        campaignId: '',
        customIdForWaitlist: ''
      })
    }
  }

  componentWillMount() {
    const { getSeason, seasonId } = this.props
    getSeason(seasonId)
  }

  async fetchSeason() {
    const { seasonId, season, dispatch } = this.props;
    // this.setState({ loading: true });

    await dispatch(getSeason(seasonId));
    // this.setState({ loading: false });
    // setTimeout(() => {
    // this.setState({
    //   loading: false,
    // })            
    // }, 5000) 
  }

  createDivisions = () => {
    const { createDivisions, seasonId } = this.props
    const { count } = this.state
    createDivisions({ count, seasonId })
  }

  deleteDivision = () => {
    const { seasonId, deleteDivision } = this.props
    deleteDivision({ seasonId })
  }

  addDivision = () => {
    const { seasonId, season, addDivision } = this.props

    const division = {
      name: `Division ${season.divisions.length + 1}`,
      players: []
    }
    addDivision({ seasonId, division })
  }

  closeSeason = async () => {
    const { seasonId } = this.props
    try {
      await closeSeason({ seasonId })
      this.setState({ message: 'Success', showConfirm: false });
    } catch {
      this.setState({ message: 'Something went wrong', showConfirm: false });
    }
    setTimeout(() => {
      this.setState({ message: null })
    }, 2000)
  }

  checkSeason = async () => {
    const { seasonId } = this.props
    const res = await checkSeason(seasonId)
    let seasonCheckData;
    seasonCheckData = res;

    this.setState({
      modalOpen: true,
      seasonCheckData,
    });
  }

  handleClose = async () => {
    this.setState({
      modalOpen: false,
    });
  }

  onDragEnd = (result) => {
    const { dragPlayer, seasonId } = this.props
    const {
      destination, // contains listId as droppableId, and item index
      source, // contains source droppableId and item index
      draggableId, // playerId
    } = result

    // if the player was dropped to the same place or cancelled, do nothing
    if (!destination || source.droppableId === destination.droppableId) return

    dragPlayer({
      seasonId,
      playerId: draggableId,
      oldList: source.droppableId,
      newList: destination.droppableId,
      oldIndex: source.index
    })
  }

  editSeason = (key, data, more = {}) => {
    const { seasonId, editSeason } = this.props

    this.setState({
      [key]: data,
      ...more,
    }, () => {
      clearTimeout(timer)
      console.log('setting all', {
            seasonId,
          ...this.state,
          ...more,
          rules: this.state.rules.replace(/\*/g, '•'),
          rulesUrl: this.state.rulesUrl,
          info: this.state.info.replace(/\*/g, '•'),
          infoRichText: this.state.infoRichText,
          notes: this.state.notes.replace(/\*/g, '•')
      })
      timer = setTimeout(() => {
        editSeason({
          seasonId,
          ...this.state,
          ...more,
          rules: this.state.rules.replace(/\*/g, '•'),
          rulesUrl: this.state.rulesUrl,
          info: this.state.info.replace(/\*/g, '•'),
          infoRichText: this.state.infoRichText,
          notes: this.state.notes.replace(/\*/g, '•')
        })
      }, 1000)
    })
  }

  hasCheckErrors = game => {
    const { season } = this.props
    if (differenceInMinutes(game.startTime, game.endTime) > 20 ||
      (game.status === 'Played' && !game.winnerId) ||
      (game.startTime < season.start || game.endTime > season.end) ||
      (game.startTime > game.endTime)
    ) {
      return true;
    }

  }

  seasonStart = async () => {
    const { seasonId, dispatch } = this.props

    try {
      await dispatch(startSeason({ seasonId }));
      this.setState({ message: 'Success', showConfirm: false });
    } catch {
      this.setState({ message: 'Something went wrong', showConfirm: false });
    }
    setTimeout(() => {
      this.setState({ message: null })
    }, 2000)

  }

  runGlicko = async () => {
    const { seasonId, dispatch } = this.props
    try {
      await dispatch(runGlicko({ seasonId }));
      this.setState({ message: 'Success', showConfirm: false });
    } catch {
      this.setState({ message: 'Something went wrong', showConfirm: false });
    }
    setTimeout(() => {
      this.setState({ message: null })
    }, 2000)
  }

  startLateRegistration = async () => {
    const { seasonId, dispatch } = this.props

    try {
      await startLateReg({ seasonId, data: { start: addDays(new Date(), 3), regEnd: addDays(new Date(), 2) } });
      this.setState({ message: 'Success', showConfirm: false });
    } catch {
      this.setState({ message: 'Something went wrong', showConfirm: false });
    }
    setTimeout(() => {
      this.setState({ message: null })
    }, 2000)
  }

  sendPromoCommunication = async (data) => {
    const { seasonId, dispatch } = this.props

    console.log('data', data);
    try {
      await sendPromotional({ seasonId, data })
      this.setState({ message: 'Success', showConfirm: false });
    } catch {
      this.setState({ message: 'Something went wrong', showConfirm: false });
    }
    setTimeout(() => {
      this.setState({ message: null })
    }, 2000)
  }

  sendCommunication = async (data) => {
    const { seasonId, dispatch } = this.props
    try {
      await sendCommunication({ seasonId, data })
      this.setState({ message: 'Success', showConfirm: false });
    } catch {
      this.setState({ message: 'Something went wrong', showConfirm: false });
    }
    setTimeout(() => {
      this.setState({ message: null })
    }, 2000)
  }

  calculateDivisionStandings = async divisionId => {
    const { seasonId, dispatch } = this.props;

    try {
      // await dispatch(calculateStandings({ seasonId, divisionId }));
      await dispatch(calculateStandings({ seasonId }));
      this.setState({ message: 'Success', showConfirm: false });
    } catch {
      this.setState({ message: 'Something went wrong', showConfirm: false });
    }

    setTimeout(() => {
      this.setState({ message: null })
    }, 2000)

  }

  pushTextComponent = (text, type) => {
    const { pushTitle, pushMessage } = this.state;
    return (
      <Container hasFlex marg="10px 20px">
        <span>{text}</span><br />
        <Container marg='20px' itemsAlign='left'>
          <Text>Title:</Text>
          <Input
            placeholder='Title'
            value={pushTitle}
            onChange={(e) => {
              console.log(e.target.value);
              this.setState({ pushTitle: e.target.value })
            }}
          />
        </Container>
        <Container marg='20px' itemsAlign='left'>
          <Text>Message:</Text>
          <Input
            placeholder='Message'
            value={pushMessage}
            onChange={(e) => this.setState({ pushMessage: e.target.value })}
          />
        </Container>
        {type === 'promo' ?
          <>
            <Container marg="10px">
              <Checkbox
                checked={this.state.includeUnsubscribed}
                onChange={() => this.setState({ includeUnsubscribed: !this.state.includeUnsubscribed })}
                label='Include unsubscribed' />
            </Container>
            <Container marg="10px">
              <Checkbox
                checked={this.state.includeAnnuals}
                onChange={() => this.setState({ includeAnnuals: !this.state.includeAnnuals })}
                label='Include annual members' />
            </Container>
          </>
          : <>
            <Container marg="10px">
              <Checkbox
                checked={this.state.includeWithdrawn}
                onChange={() => this.setState({ includeWithdrawn: !this.state.includeWithdrawn })}
                label='Include withdrawn' />
            </Container>
            <Container marg="10px">
              <Checkbox
                checked={this.state.includeSnoozed}
                onChange={() => this.setState({ includeSnoozed: !this.state.includeSnoozed })}
                label='Include snoozed' />
            </Container>
            <Container marg="10px">
              <Checkbox
                checked={this.state.includeAllRegistered}
                onChange={() => this.setState({ includeAllRegistered: !this.state.includeAllRegistered })}
                label='Send to all registered players' />
            </Container>
          </>
        }
      </Container>
    )
  };

  confirmBroadcastContent = title => {
    const text = title || "This will send an email to all allocated players this season.";
    return (
      <Container marg="10px 20px">
        <Container marg="10px">
          <span>{text}</span><br />
          {this.state.campaignId && <span>Customerio Broadcast Id: {this.state.campaignId}</span>}
        </Container>
        <Container marg="10px">
          <Checkbox
            checked={this.state.includeWithdrawn}
            onChange={() => this.setState({ includeWithdrawn: !this.state.includeWithdrawn })}
            label='Include withdrawn' />
        </Container>
        <Container marg="10px">
          <Checkbox
            checked={this.state.includeSnoozed}
            onChange={() => this.setState({ includeSnoozed: !this.state.includeSnoozed })}
            label='Include snoozed' />
        </Container>
        <Container marg="10px">
          <Checkbox
            checked={this.state.includeNewPlayersOnly}
            onChange={() => this.setState({ includeNewPlayersOnly: !this.state.includeNewPlayersOnly })}
            label='Include new players only' />
        </Container>
        <Container marg="10px">
          <Checkbox
            checked={this.state.includeAllRegistered}
            onChange={() => this.setState({ includeAllRegistered: !this.state.includeAllRegistered })}
            label='Send to all registered players' />
        </Container>
        {this.state.allowCampaignOverride && <Container marg="10px">
          <Input
            placeholder='Broadcast Id'
            value={this.state.campaignId}
            onChange={(e) => this.setState({ campaignId: e.target.value })}
          />
        </Container>}
      </Container>
    )
  };

  confirmPromoContent = () => {
    const text = "This will send an email to all users who have ever registered for the league in the past 6 months and everyone nearby to promote the new season.";
    return (
      <Container marg="10px 20px">
        <span>{text}</span><br />
        <Container marg="10px">
          <Checkbox
            checked={this.state.includeUnsubscribed}
            onChange={() => this.setState({ includeUnsubscribed: !this.state.includeUnsubscribed })}
            label='Include unsubscribed?' />
        </Container>
        <Container marg="10px">
          <Checkbox
            checked={this.state.includeAnnuals}
            onChange={() => this.setState({ includeAnnuals: !this.state.includeAnnuals })}
            label='Include annual members?' />
        </Container>
        <Container marg="10px">
          <Dropdown
            placeholder='Select a promo type'
            selection
            options={this.state.promoTypes}
            onChange={(e, data) => {
              console.log(data);
              const option = data.options.find(opt => opt.value === data.value);
              this.setState({ campaignId: option.broadcast, selectedPromoType: data.value });
              // this.setState({ campaignId: data.value });
            }}
          />
        </Container>
        {this.state.selectedPromoType === 'custom' && <Container marg="10px">
          <Input
            placeholder='Broadcast Id'
            value={this.state.campaignId}
            onChange={(e) => this.setState({ campaignId: e.target.value })}
          />
        </Container>}
      </Container>
    )
  };

  confirmPromoWaitlistContent = () => {
    const text = "This will send an email to all users who have joined the waitlist.";
    return (
      <Container marg="10px 20px">
        <span>{text}</span><br />
        <Container marg="10px">
          <Checkbox
            checked={this.state.includeUnsubscribed}
            onChange={() => this.setState({ includeUnsubscribed: !this.state.includeUnsubscribed })}
            label='Include unsubscribed?' />
        </Container>
        <Container marg="10px">
          <Checkbox
            checked={this.state.includeAnnuals}
            onChange={() => this.setState({ includeAnnuals: !this.state.includeAnnuals })}
            label='Include annual members?' />
        </Container>
        <Container marg="10px">
          <Input
            placeholder='Custom - Broadcast Id'
            value={this.state.customIdForWaitlist}
            onChange={(e) => this.setState({ customIdForWaitlist: e.target.value })}
          />
        </Container>
      </Container>
    )
  };

  confirmAnnualMembersPromoContent = () => {
    const { season } = this.props;
    const seasonsToPromoteText = this.state.includeAllSeasons ?
      `every ${season.name} season of all leagues` :
      `${season.league.name} - ${season.name}`;
    const title = "Confirm sending of Annual Members Promotional Broadcast";
    const text = `A broadcast will be sent for ${seasonsToPromoteText}.`;
    return (
      <Container marg="10px 20px">
        <Text>{title}</Text>
        <Container marg="10px">
          <Checkbox
            checked={this.state.includeAllSeasons}
            onChange={() => this.setState({ includeAllSeasons: !this.state.includeAllSeasons })}
            label='Include all seasons?' />
        </Container>
        <Text><small>{text}</small></Text>
      </Container>
    )
  };

  render() {
    const { match, season } = this.props
    const {
      name, price, annualPrice, individualPrice, start, end, regStart, regEnd,
      midPoint, rules, rulesUrl, info, infoRichText, joinAlert, loading, showConfirm, slug,
      confirmHeader, confirmContent, confirmAction, pushTitle, pushMessage, notes,
      regularWeeks, playOffWeeks, courts
    } = this.state

    console.log(pushTitle);
    console.log(pushMessage);
    if (!season || !season.users) return <Loader active inline='centered' />

    return (
        <React.Fragment>
            <Container contentJustify="center">
                {season.status && (
                    <Message
                        positive={season.status === "live" ? true : false}
                        negative={season.status === "closed" ? true : false}
                        warning={season.status === "hidden" ? true : false}
                        header={`Season is ${season.status}`}
                        content={
                            <>
                                <NavLink to={`${match.url}/divisions`}>
                                    Show Divisions
                                </NavLink>
                                <NavLink to={`${match.url}/games`}>
                                    Show Games
                                </NavLink>
                                <br />
                                <NavLink to={`${match.url}/communications`}>
                                    View Communications
                                </NavLink>
                            </>
                        }
                    />
                )}
            </Container>
            <Container>
                {this.state.message && (
                    <Message
                        positive={
                            this.state.message === "Success" ? true : false
                        }
                        negative={
                            this.state.message === "Success" ? false : true
                        }
                        header={this.state.message}
                    />
                )}
            </Container>
            <Container noFlex marg="20px 0 0" itemsAlign="flex-end">
                <Dropdown
                    text="Actions"
                    button
                    direction="left"
                    // options={this.actionOptions()}
                    onChange={this.handleAction}
                >
                    <Dropdown.Menu>
                        <Dropdown.Item
                            content={
                                <Clickable
                                    onClick={() =>
                                        this.setState({
                                            showConfirm: true,
                                            confirmHeader: "Are you sure?",
                                            confirmContent:
                                                "This will put the season live, create all games and calculate standings ",
                                            confirmAction: this.seasonStart,
                                        })
                                    }
                                >
                                    <Text
                                        size="13px"
                                        weight="bold"
                                        col={theme.GREEN}
                                    >
                                        Start Season!
                                    </Text>
                                </Clickable>
                            }
                        />
                        <Dropdown.Item
                            content={
                                <Clickable
                                    onClick={() =>
                                        this.setState({
                                            showConfirm: true,
                                            confirmHeader: "Are you sure?",
                                            confirmContent:
                                                "This will close the season. All games will be marked as completed. This cannot be undone.",
                                            confirmAction: this.closeSeason,
                                        })
                                    }
                                >
                                    <Text size="13px" col="red">
                                        Close Season
                                    </Text>
                                </Clickable>
                            }
                        />
                        <Dropdown.Item
                            content={
                                <Clickable onClick={this.checkSeason}>
                                    <Text size="13px">Verify Season Data</Text>
                                </Clickable>
                            }
                        />
                        <Dropdown.Item
                            content={
                                <Clickable
                                    onClick={() =>
                                        this.setState({
                                            showDivisionPlan: true,
                                        })
                                    }
                                >
                                    <Text size="13px">Show Division Plan</Text>
                                </Clickable>
                            }
                        />
                        <Dropdown.Item
                            content={
                                <Clickable
                                    onClick={() =>
                                        window.open(
                                            `https://dashboard.momentumvolleyball.ca/dashboard/37-season-stats?season_id=${season._id}`
                                        )
                                    }
                                >
                                    <Text size="13px">Show Stats</Text>
                                </Clickable>
                            }
                        />
                        <Dropdown.Item
                            content={
                                <Clickable
                                    onClick={() =>
                                        this.setState({
                                            showConfirm: true,
                                            confirmHeader: "Are you sure?",
                                            confirmContent:
                                                "This will copy the main fields from this season on to 'Late Registratiom' and start accepting signups",
                                            confirmAction:
                                                this.startLateRegistration,
                                        })
                                    }
                                >
                                    <Text size="13px">
                                        Start Late Registration
                                    </Text>
                                </Clickable>
                            }
                        />
                        <Dropdown.Item
                            content={
                                <Clickable
                                    onClick={() =>
                                        this.setState({
                                            showConfirm: true,
                                            confirmHeader: "Are you sure?",
                                            campaignId: null,
                                            allowCampaignOverride: true,
                                            // confirmContent: "Notifications will be sent to ALL active season players.",
                                            confirmContent: () => {
                                                // this.setState({ campaignId: ' });
                                                return this.confirmBroadcastContent();
                                            },
                                            confirmAction: () =>
                                                this.sendCommunication({
                                                    type: "broadcast",
                                                    metadata: {
                                                        includeWithdrawn:
                                                            this.state
                                                                .includeWithdrawn,
                                                        includeSnoozed:
                                                            this.state
                                                                .includeSnoozed,
                                                        includeAllRegistered:
                                                            this.state
                                                                .includeAllRegistered,
                                                        includeNewPlayersOnly:
                                                            this.state
                                                                .includeNewPlayersOnly,
                                                        campaignId:
                                                            this.state
                                                                .campaignId,
                                                    },
                                                }),
                                            // confirmAction: () => this.sendCommunication({ type: 'weekly_games'}),
                                        })
                                    }
                                >
                                    <Text size="13px">
                                        Send Broadcast to Players
                                    </Text>
                                </Clickable>
                            }
                        />
                        {/* <Dropdown.Item content={(<Clickable onClick={() => this.setState({
                  showConfirm: true,
                  confirmHeader: 'Are you sure?',
                  confirmContent: "Season end reminders will be sent to ALL season players.",
                  confirmAction: () => this.sendCommunication({ type: 'end_reminder'}),
                })}><Text size="13px">Send End Reminder Email</Text></Clickable>)}/> */}
                        <Dropdown.Item
                            content={
                                <Clickable
                                    onClick={() =>
                                        this.setState({
                                            showConfirm: true,
                                            confirmHeader: "Are you sure?",
                                            confirmContent: () =>
                                                this.confirmPromoContent(),
                                            confirmAction: () =>
                                                this.sendPromoCommunication({
                                                    type: "season_promo",
                                                    metadata: {
                                                        includeAnnuals:
                                                            this.state
                                                                .includeAnnuals,
                                                        includeUnsubscribed:
                                                            this.state
                                                                .includeUnsubscribed,
                                                        campaignId:
                                                            this.state
                                                                .campaignId,
                                                    },
                                                }),
                                        })
                                    }
                                >
                                    <Text size="13px">
                                        Send Promotional Broadcast
                                    </Text>
                                </Clickable>
                            }
                        />
                        <Dropdown.Item
                            content={
                                <Clickable
                                    onClick={() =>
                                        this.setState({
                                            showConfirm: true,
                                            confirmHeader: "Are you sure?",
                                            confirmContent: () =>
                                                this.confirmPromoWaitlistContent(),
                                            confirmAction: () =>
                                                this.sendPromoCommunication({
                                                    type: "waitlist",
                                                    metadata: {
                                                        includeAnnuals:
                                                            this.state
                                                                .includeAnnuals,
                                                        includeUnsubscribed:
                                                            this.state
                                                                .includeUnsubscribed,
                                                        campaignId:
                                                            this.state
                                                                .customIdForWaitlist,
                                                    },
                                                }),
                                        })
                                    }
                                >
                                    <Text size="13px">
                                        Send Promotional Waitlist Email
                                    </Text>
                                </Clickable>
                            }
                        />
                        <Dropdown.Item
                            content={
                                <Clickable
                                    onClick={() =>
                                        this.setState({
                                            showConfirm: true,
                                            confirmHeader: "Are you sure?",
                                            confirmContent: () =>
                                                this.confirmAnnualMembersPromoContent(),
                                            confirmAction: () => {
                                                const { season } = this.props;
                                                this.sendPromoCommunication({
                                                    type: "annual_pass",
                                                    metadata: {
                                                        includeAllSeasons:
                                                            this.state
                                                                .includeAllSeasons,
                                                    },
                                                });
                                            },
                                        })
                                    }
                                >
                                    <Text size="13px">
                                        Send Annual Members Promo Broadcast
                                    </Text>
                                </Clickable>
                            }
                        />
                        <Dropdown.Item
                            content={
                                <Clickable
                                    onClick={() =>
                                        this.setState({
                                            showConfirm: true,
                                            confirmHeader: "Are you sure?",
                                            confirmContent:
                                                "This will dispatch a welcome email to all recently joined league players.",
                                            confirmAction: () => {
                                                this.sendCommunication({
                                                    type: "welcome_new",
                                                });
                                            },
                                        })
                                    }
                                >
                                    <Text size="13px">
                                        Send Welcome Email for new league
                                        players
                                    </Text>
                                </Clickable>
                            }
                        />
                        <Dropdown.Item
                            content={
                                <Clickable
                                    onClick={() =>
                                        this.setState({
                                            showConfirm: true,
                                            confirmHeader:
                                                "Enter Title and Message",
                                            confirmContent: () =>
                                                this.pushTextComponent(
                                                    "This will send a push notification to all users who have ever registered for the league and everyone near.",
                                                    "promo"
                                                ),
                                            confirmAction: () =>
                                                this.sendPromoCommunication({
                                                    type: "promo_push",
                                                    metadata: {
                                                        title: this.state
                                                            .pushTitle,
                                                        message:
                                                            this.state
                                                                .pushMessage,
                                                        includeAnnuals:
                                                            this.state
                                                                .includeAnnuals,
                                                        includeUnsubscribed:
                                                            this.state
                                                                .includeUnsubscribed,
                                                    },
                                                }),
                                        })
                                    }
                                >
                                    <Text size="13px">
                                        Send Promotional Push
                                    </Text>
                                </Clickable>
                            }
                        />
                        <Dropdown.Item
                            content={
                                <Clickable
                                    onClick={() =>
                                        this.setState({
                                            showConfirm: true,
                                            confirmHeader:
                                                "Enter Title and Message",
                                            allowCampaignOverride: false,
                                            // set to customerio's push campaign (not great, but works for now)
                                            campaignId: 22,
                                            // confirmContent: "This will send a push notification to all users who have ever registered for the league and everyone near to promote the new season.",
                                            confirmContent: () => {
                                                const { season } = this.props;
                                                return this.pushTextComponent(
                                                    `This will only send a push notification to ${season.league.name} ${season.name}'s players.`,
                                                    "reminder"
                                                );
                                            },
                                            confirmAction: () =>
                                                this.sendCommunication({
                                                    type: "broadcast",
                                                    metadata: {
                                                        title: this.state
                                                            .pushTitle,
                                                        message:
                                                            this.state
                                                                .pushMessage,
                                                        includeWithdrawn:
                                                            this.state
                                                                .includeWithdrawn,
                                                        includeSnoozed:
                                                            this.state
                                                                .includeSnoozed,
                                                        includeAllRegistered:
                                                            this.state
                                                                .includeAllRegistered,
                                                        campaignId:
                                                            this.state
                                                                .campaignId,
                                                    },
                                                }),
                                        })
                                    }
                                >
                                    <Text size="13px">
                                        Send Push To Season Players
                                    </Text>
                                </Clickable>
                            }
                        />
                        <Dropdown.Item
                            content={
                                <Clickable
                                    onClick={() =>
                                        this.setState({
                                            showConfirm: true,
                                            confirmHeader: "Are you sure?",
                                            confirmContent:
                                                "This will reset league ratings and redo for every league match played on Leagues.",
                                            confirmAction: this.runGlicko,
                                        })
                                    }
                                >
                                    <Text size="13px">Redo League Ratings</Text>
                                </Clickable>
                            }
                        />
                        <Dropdown.Item
                            content={
                                <Clickable
                                    onClick={() =>
                                        this.setState({
                                            showConfirm: true,
                                            confirmHeader: "Update Standings?",
                                            confirmContent:
                                                "This will update the standings for ALL divisions.",
                                            confirmAction: () =>
                                                this.calculateDivisionStandings(),
                                        })
                                    }
                                >
                                    <Text size="13px">Update Standings</Text>
                                </Clickable>
                            }
                        />

                        {/* <Dropdown.Item>
                <SButton onClick={this.closeSeason}>Close Season!</SButton>
              </Dropdown.Item>  
              <Dropdown.Item>
                <SButton onClick={this.checkSeason}>Verify Season Data</SButton>
              </Dropdown.Item>
              <Dropdown.Item>
                <SButton onClick={this.startLateRegistration}>Start Late Registration</SButton>
              </Dropdown.Item>
              <Dropdown.Item>
                <SButton onClick={this.runGlicko}>Reset Elo</SButton>
              </Dropdown.Item>                                  */}
                    </Dropdown.Menu>
                </Dropdown>
            </Container>
            <Container noFlex marg=" 0 0 20px" flexDir="row" max-wid="50%">
                <Modal
                    // trigger={<Button text='Verify Season' click={this.checkSeason} />}
                    open={this.state.modalOpen}
                    onClose={this.handleClose}
                    basic
                    size="large"
                >
                    {/* <Header icon='browser' content='Cookies policy' /> */}
                    <Modal.Content>
                        {this.state.seasonCheckData && (
                            <Container>
                                <h1>Season Status:</h1>
                                <h2>Played games without winner/score:</h2>
                                <p>
                                    {this.state.seasonCheckData.playedWithoutScore.join(
                                        " "
                                    )}{" "}
                                </p>
                                <h2>Date outside season start/end time:</h2>
                                <p>
                                    {" "}
                                    {this.state.seasonCheckData.wrongDate.join(
                                        " "
                                    )}{" "}
                                </p>
                                <Table celled>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>
                                                id
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                Division/Week
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                Status
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                Has score
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                Winner id
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                Start time
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                End time
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {this.state.seasonCheckData.games.map(
                                            (game) => (
                                                <Table.Row
                                                    error={this.hasCheckErrors(
                                                        game
                                                    )}
                                                    key={game._id}
                                                >
                                                    <Table.Cell>
                                                        {game._id} - {game.title || ''}
                                                    </Table.Cell>
                                                     <Table.Cell>
                                                        {game.division}/{game.week}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {game.status}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {game.score.sets
                                                            .length > 0
                                                            ? "Yes"
                                                            : "No"}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {game.winnerId}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {game.startTime && format(
                                                            new Date(game.startTime),
                                                            "MMM dd yyyy, H:mm"
                                                        )}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {game.endTime && format(
                                                            new Date(game.endTime),
                                                            "MMM dd yyyy, H:mm"
                                                        )}
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        )}
                                    </Table.Body>
                                </Table>
                            </Container>
                        )}
                    </Modal.Content>
                    <Modal.Actions>
                        <SButton
                            color="green"
                            onClick={this.handleClose}
                            inverted
                        >
                            <Icon name="checkmark" /> Ok
                        </SButton>
                    </Modal.Actions>
                </Modal>
            </Container>
            <Container noFlex marg=" 0 0 20px" flexDir="row" max-wid="50%">
                <Container marg=" 0 0 20px" itemsAlign="left">
                    <Text>Name:</Text>
                    <Input
                        placeholder="Name"
                        onChange={(e) =>
                            this.editSeason("name", e.target.value)
                        }
                        value={name}
                    />
                </Container>
                <Container marg=" 0 0 20px 20px" itemsAlign="left">
                    <Text>Price:</Text>
                    <Input
                        placeholder="Price"
                        onChange={(e) =>
                            this.editSeason("price", e.target.value)
                        }
                        value={price}
                    />
                </Container>
                {/* <Container marg=" 0 0 20px 20px" itemsAlign="left">
                    <Text>Annual Price:</Text>
                    <Input
                        placeholder="Price"
                        onChange={(e) =>
                            this.editSeason("annualPrice", e.target.value)
                        }
                        value={annualPrice}
                    />
                </Container> */}
                <Container marg=" 0 0 20px 20px" itemsAlign="left">
                    <Text>Individual Price:</Text>
                    <Input
                        placeholder="Price"
                        onChange={(e) =>
                            this.editSeason("individualPrice", e.target.value)
                        }
                        value={individualPrice}
                    />
                </Container>
                <Container marg=" 0 0 20px 20px" itemsAlign="left">
                    <Text>Slug:</Text>
                    <Input
                        placeholder="Price"
                        onChange={(e) =>
                            this.editSeason("slug", e.target.value)
                        }
                        value={slug}
                    />
                </Container>
            </Container>
            <Container flexDir="row">
                <Container marg=" 0 0 20px 0px" itemsAlign="left">
                    <Text>Rules:</Text>
                    <Input
                        placeholder="Rules url"
                        value={rulesUrl}
                        onChange={(e) =>
                            this.editSeason("rulesUrl", e.target.value)
                        }
                    />
                </Container>
            </Container>
            <Container flexDir="row">
                <Container marg=" 0 0 20px 0px" itemsAlign="left">
                    <Form>
                        <Text>Info:</Text>
                        <TextArea
                            placeholder="info"
                            rows="10"
                            value={info}
                            onChange={(e) =>
                                this.editSeason("info", e.target.value)
                            }
                        />
                    </Form>
                </Container>
                <Container marg=" 0 0 20px 20px" itemsAlign="left">
                    <Form>
                        <Text>Join Alert:</Text>
                        <TextArea
                            placeholder="Join Alert"
                            rows="10"
                            value={joinAlert}
                            onChange={(e) =>
                                this.editSeason("joinAlert", e.target.value)
                            }
                        />
                    </Form>
                </Container>
            </Container>
            <Container flexDir="row">
                <Container marg=" 0 0 20px 0px" itemsAlign="left">
                    <Text>Rich Text Info:</Text>
                    <RichTextInput
                        value={infoRichText}
                        onChange={(html) => {
                            this.editSeason("infoRichText", html);
                        }}
                    />
                </Container>
            </Container>
            <Container flexDir="row" noFlex marg="0 0 30px">
                <Container itemsAlign="flex-start">
                    Registration Start
                    <DatePicker
                        selected={regStart}
                        onChange={(date) => this.editSeason("regStart", date)}
                        // dateFormat='dd/MM/yyyy'
                        dateFormat="dd/MM/yyyy, HH:mm"
                        timeFormat="HH:mm"
                        showTimeSelect
                    />
                </Container>

                <Container itemsAlign="flex-start">
                    Registration end
                    <DatePicker
                        selected={regEnd}
                        onChange={(date) => this.editSeason("regEnd", date)}
                        dateFormat="dd/MM/yyyy, HH:mm"
                        timeFormat="HH:mm"
                        showTimeSelect
                    />
                </Container>
                <Container itemsAlign="flex-start">
                    Season Start
                    <DatePicker
                        selected={start}
                        onChange={(date) => this.editSeason("start", date)}
                        dateFormat="dd/MM/yyyy, HH:mm"
                        timeFormat="HH:mm"
                        showTimeSelect
                    />
                </Container>
                <Container marg=" 0 0 20px 20px" itemsAlign="flex-start">
                    Season End
                    <DatePicker
                        selected={end}
                        onChange={(date) => this.editSeason("end", date)}
                        dateFormat="dd/MM/yyyy, HH:mm"
                        timeFormat="HH:mm"
                        showTimeSelect
                        disabled
                    />
                </Container>
                </Container>
            <Container flexDir="row">
                <Container marg=" 0 0 20px 0px" itemsAlign="left">
                     Regular Weeks
                     <Input
                        type='number'
                        placeholder='Regular Weeks'
                        onChange={e => {
                          const val = Number(e.target.value);
                          const playOffVal = Number(this.state.playOffWeeks);
                          let add = 0;
                          const newState = {};
                          if (!isNaN(val)) {
                            newState.regularWeeks = Number(val);
                            add += newState.regularWeeks;
                          }
                          if (!isNaN(playOffVal)) {
                            // newState.regularWeeks = Number(val);
                            add += Number(playOffVal);
                          }
                          const endWeek = zonedTimeToUtc(setHours(addWeeks(start, add), 23), defaultTimezone);
                          this.setState({ ...newState, end: endWeek }, () => {
                            this.editSeason("end", endWeek, { ...newState, end: endWeek });
                            console.log('setting', { ...newState, end: endWeek });
                          });
                        }}
                        value={regularWeeks}
                      />
                </Container>
                <Container marg=" 0 0 20px 0px" itemsAlign="left">
                     PlayOff Weeks
                     <Input
                        type='number'
                        placeholder='Regular Weeks'
                        onChange={e => {
                          const val = Number(e.target.value);
                          const regularVal = Number(this.state.regularWeeks);
                          let add = 0;
                          const newState = {};
                          if (!isNaN(val)) {
                            newState.playOffWeeks = Number(val);
                            add += newState.playOffWeeks;
                          }
                          if (!isNaN(regularVal)) {
                            // newState.regularWeeks = Number(val);
                            add += Number(regularVal);
                          }
                          const endWeek = zonedTimeToUtc(setHours(addWeeks(start, add), 23), defaultTimezone);
                          this.setState({ ...newState, end: endWeek }, () => {
                            this.editSeason("end", endWeek, { ...newState, end: endWeek });
                            console.log('setting', { ...newState, end: endWeek });
                          });
                        }}
                        value={playOffWeeks}
                      />
                </Container>
                <Container marg=" 0 0 20px 0px" itemsAlign="left">
                     Courts
                     <Input
                        type='number'
                        placeholder='Number of courts'
                        onChange={e => {
                          if (!isNaN(Number(e.target.value))) {
                            this.editSeason("courts", Number(e.target.value)); 
                          }
                        }}
                        value={courts}
                      />
                </Container>

                {/* <Container  itemsAlign="flex-start">
                    Mid Point
                    <DatePicker
                        selected={midPoint}
                        onChange={(date) => this.editSeason("midPoint", date)}
                        dateFormat="dd/MM/yyyy, HH:mm"
                        timeFormat="HH:mm"
                        showTimeSelect
                        disabled
                    />
                </Container> */}

                
            </Container>
            <Container marg=" 0 0 20px 0px" itemsAlign="left">
                <Form>
                    <Text>Notes:</Text>
                    <TextArea
                        placeholder="notes"
                        rows="10"
                        value={notes}
                        onChange={(e) =>
                            this.editSeason("notes", e.target.value)
                        }
                    />
                </Form>
            </Container>
            <Container pad="40px">
                <SeasonPlayingTeamsTable season={season} />
                {/* <DivisionPlanTable season={season}/> */}
            </Container>
            {/* <Container pad="40px" itemsAlign="center" contentJustify="center" selfAlign="center"> */}
            {/* <Container noFlex marg=' 0 0 20px' flexDir='row'> */}
            <Modal
                // trigger={<Button text='Verify Season' click={this.checkSeason} />}
                open={this.state.showDivisionPlan}
                onClose={() =>
                    this.setState({
                        showDivisionPlan: false,
                    })
                }
                size="fullscreen"
            >
                <Modal.Content>
                    <Grid centered>
                        <DivisionPlanTable season={season} />
                    </Grid>
                </Modal.Content>
            </Modal>
            {/* </Container> */}
            <Confirm
                open={showConfirm}
                header={confirmHeader}
                content={confirmContent}
                onCancel={() => this.setState({ showConfirm: false })}
                onConfirm={confirmAction}
            />
        </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const seasonId = ownProps.match.params.id

  return {
    seasonId,
    season: state.seasons.find(s => s._id === seasonId)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getSeason: payload => {
      dispatch(getSeason(payload))
    },
    createDivisions: payload => {
      dispatch(createDivisions(payload))
    },
    dragPlayer: payload => {
      dispatch(dragPlayer(payload))
    },
    addDivision: payload => {
      dispatch(addDivision(payload))
    },
    deleteDivision: payload => {
      dispatch(deleteDivision(payload))
    },
    startSeason: payload => {
      dispatch(startSeason(payload))
    },
    // closeSeason: payload => {
    //   dispatch(closeSeason(payload))
    // },
    // checkSeason: payload => {
    //   dispatch(checkSeason(payload))
    // },    
    editSeason: payload => {
      dispatch(editSeason(payload))
    },
    dispatch,
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(League)
)
