import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import playerSkillAssesment from "../graphql/queries/playerSkillAssesment";

const LeagueUserSkillAssesment = ({ playerId, seasonId }) => {
  const { loading, data, error, refetch } = useQuery(playerSkillAssesment, {
    variables: { playerId },
  });

  if (!data || !data.skillAssesment) return null;
  return <span>{data.skillAssesment.comments}</span>
}

export default LeagueUserSkillAssesment;