import axios from 'axios'
import getToken from './getToken'

const API_URL = process.env.REACT_APP_API_URL

// set headers
const setHeaders = async () => {
  let token = await getToken()
  if (token) {
    axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
  }
}

// global api
const Api = {
  async get(endpoint, params, next) {
    await setHeaders()
    axios.get(`${API_URL}${endpoint}`)
      .then(response => next(false, response.data))
      .catch(error => next(error.response.data))
  },
  async getAsync(endpoint, params) {
    await setHeaders()
    return await axios.get(`${API_URL}${endpoint}`)
  },  
  async post(endpoint, params, next) {
    await setHeaders()
    axios
      .post(`${API_URL}${endpoint}`, params)
      .then(response => next(false, response.data))
      .catch(error => next(error.response.data))
  },
  async postAsync(endpoint, params) {
    await setHeaders()
    return await axios
      .post(`${API_URL}${endpoint}`, params)
  },  
  async put(endpoint, params, next) {
    await setHeaders()
    axios
      .put(`${API_URL}${endpoint}`, params)
      .then(response => next(false, response.data))
      .catch(error => next(error.response.data))
  },
  async putAsync(endpoint, params, next) {
    await setHeaders()
    return await axios.put(`${API_URL}${endpoint}`, params)
  },  
  async delete(endpoint, next) {
    await setHeaders()
    axios
      .delete(`${API_URL}${endpoint}`)
      .then(response => next(false, response))
      .catch(error => next(error.response.data))
  },
}

export default Api
