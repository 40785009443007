import Api from 'lib/api'

const checkSeason = async seasonId => {
  //return dispatch => {
  const res = await Api.getAsync(`/seasons/${seasonId}/check`);
  return res.data;
  //}
}

export default checkSeason;
