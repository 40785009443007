import { gql } from '@apollo/client';

const teamsByName = gql`
  query TeamsByName($name: String) {
    teamPagination(
      filter: { q: $name },
      page: 1, 
      perPage: 20
    ) {
        items {
          id: _id
          _id
          name
          gender
          avatar
          captain {
             _id
            name
            username
            firstName
            lastName
          }
    } 
    }
  }
`;

export default teamsByName;
