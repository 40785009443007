import { gql } from '@apollo/client';

const geographicAreaByName = gql`
  query GeographicAreaByName($name: String, $type: EnumGeographicAreaType, $parent: MongoID) {
    geographicAreaPagination(
      filter: { search: $name, type: $type, parent: $parent },
      page: 1, 
      perPage: 50
    ) {
        items {
            _id
            name {
              en_GB
            }
        } 
    }
  }
`;

export default geographicAreaByName;
