
const colors = ["#6C757D", "#142932", "#01D985", "white"];
const formats = [
    [
        {
            className: "ql-bold"
        },
        {
            className: "ql-italic"
        },
        {
            className: "ql-underline"
        },
        {
            className: "ql-strike"
        }
    ],
    [
        {
            className: "ql-color",
            options: colors
        },
        {
            className: "ql-background",
            options: colors
        }
    ],
    [
        {
            className: "ql-header",
            value: "1"
        },
        {
            className: "ql-header",
            value: "2"
        },
        {
            className: "ql-blockquote"
        }
    ],
    [
        {
            className: "ql-list",
            value: "ordered"
        },
        {
            className: "ql-list",
            value: "bullet"
        },
    ],
    [
        {
            className: 'ql-link'
        },
        {
            className: 'ql-video'
        },
    ],

]

export default formats;