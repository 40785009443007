import Api from 'lib/api'

const startSeason = (payload) => {
  return dispatch => {
    Api.post('/seasons/startSeason', payload, (err, res) => {
      if(err) return console.log('err', err)
    })
  }
}

export default startSeason
