import { gql } from '@apollo/client';

const playerNotifications = gql`
  query PlayerNotifications($seasonId: MongoID, $playerId: MongoID) {
    notifications(
      filter: { seasonId: $seasonId, recipientId: $playerId, status: "new" },
    ) {
      _id
      createdAt,
    }
  }
`;

export default playerNotifications;
