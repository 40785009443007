import React from 'react'
import { withRouter } from 'react-router-dom'
import { Button } from 'components'
import Api from 'lib/api'
import { connect } from 'react-redux'

import { setUser } from 'store/actions/user'

const LoginButton = ({ email, password, setUser, onError }) => (
  <Button
    text='Login'
    click={() => {
      Api.post(
        '/users/login',
        { email, password },
        (err, res) => {
          if(err) {
            onError(err.message || 'Error Logging In');
            return console.warn('err', err)
          }
          if (res.user.admin) {
            console.log(res)
            // set JWT to device storage
            localStorage.setItem('token', res.token)
  
            // save data to redux
            setUser(res.user)
          } else {
            onError('Unathorized Access');
          }
        }
      )
    }}
  />
)

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    setUser: payload => {
      dispatch(setUser(payload))
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginButton)
)