import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Dropdown } from "semantic-ui-react";
import courtsByName from "../../graphql/queries/courtsByName";

const CourtsSelect = ({ onSelect }) => {
  const [name, setName] = useState();
  const { loading, data, error, refetch } = useQuery(courtsByName, {
    variables: { name },
    // onCompleted(response) {
    // },
  });

  return (
    <Dropdown
      placeholder='Select a Court'
      search
      fluid
      selection
      onSearchChange={(e) => {
        console.log(e);
        console.log(e.target.value);
        setName(e.target.value);
        // refetch({ search: e.target.value})
      }}
      onChange={(e, data) => {
        console.log(e.target);
        console.log(data);        
        onSelect && onSelect(data.options.find(opt => opt.value === data.value));
      }}
      options={data ? data.courtPagination.items.map(c => ({ key: c._id, value: c._id, text: c.name, ...c })) : []}
    />    
  )
}

export default CourtsSelect;