import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import React, { useState } from "react";
import { defaultTimezone } from "../lib/constants";
import DatePicker from 'react-datepicker'

const ZonedDatePicker = ({browserTimezone, onChange, selected, ...rest}) => {
    
    // return null;
    return <DatePicker
              selected={selected && (browserTimezone ? selected : utcToZonedTime(selected, defaultTimezone))}
              onChange={(date) => {
                onChange(browserTimezone ? date : zonedTimeToUtc(date, defaultTimezone))
              }}
              {...rest}
            />
}

export default ZonedDatePicker;