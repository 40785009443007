import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom"
// navigators
import NotUser from './NotUser'
import User from './User'

const Navigator = (props) => {
  const { user } = props
  return user.email ? <User /> : <NotUser/>
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default withRouter(connect(mapStateToProps)(Navigator))
