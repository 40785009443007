import { gql } from '@apollo/client';

const usersByName = gql`
  query UsersByName($name: String) {
    userPagination(
      filter: { q: $name },
      page: 1, 
      perPage: 20
    ) {
        items {
          id: _id
          _id
          username
          firstName
          lastName
          name
          gender
          email
          avatar
          # notifCount
          # oldUserId
          # oldProfileId
          # cardDigits
          # customerId
          # hidden
          # deleted
          # services {
          #   password
          #   facebook
          #   __typename
          # }
          # boarded
          # resetPasswordToken
          # devices {
          #   token
          #   os
          #   __typename
          # }
          # phone
          # lta
          # britNo
          # dateOfBirth
          # mobile
          # skillLevel
          # leagueEntrySkillLevel
          # spinRating
          # lastPushNotificationAt
          # newGamesNotificationAt
          # flagged
          # admin
          # notificationSettings {
          #   messages {
          #     email
          #     push
          #     required
          #     __typename
          #   }
          #   nearby {
          #     email
          #     push
          #     required
          #     __typename
          #   }
          #   unsubscribe
          #   __typename
          # }
          # gameFilters {
          #   distance
          #   opponentLevel
          #   opponentGender
          #   courtType
          #   courtLocation
          #   floodlit
          #   matchUserSkill
          #   __typename
          # }
          # settings {
          #   distance
          #   opponentLevel
          #   opponentGender
          #   courtType
          #   courtLocation
          #   floodlit
          #   __typename
          # }
          # availability
          # location {
          #   type
          #   coordinates
          #   timeZone
          #   city
          #   country
          #   state
          #   code
          #   text
          #   __typename
          # }
          # appInfo {
          #   version
          #   deviceBrand
          #   deviceName
          #   deviceModel
          #   deviceBaseOS
          #   deviceSystemName
          #   deviceSystemVersion
          #   pushPermissions
          #   __typename
          # }
          # localeInfo {
          #   deviceTimeZone
          #   uses24HourClock
          #   usesMetricSystem
          #   languageTag
          #   isRTL
          #   userOverridden
          #   __typename
          # }
          # membership {
          #   type
          #   start
          #   end
          #   fee
          #   __typename
          # }
          # lastOnline
          # lastSocketConnect
          # activeSocketConnections
          # createdAt
          # newMessages
          # newNotifications
          # courts
          # games
          # firstNewChatOfDayAt
          # newChatsInDay
          # __typename
    } 
    }
  }
`;

export default usersByName;
