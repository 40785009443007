import React from 'react'
import { withRouter } from 'react-router-dom'
import { Container, Text, Clickable } from 'components'
import { connect } from 'react-redux'
import { getAll } from 'store/actions/leagues'

class Leagues extends React.Component{
  componentWillMount() {
    this.props.getAll()
  }
  render() {
    const { leagues, history } = this.props
    if(!leagues) return
    
    return(
      <div className="ui grid centered">
        <div className="row">
          <h1>Leagues</h1>
        </div>  
        <div className="ui segment">
          {leagues.map(x => (
            <div className="row" key={x._id}>
              <Clickable
                key={x._id}
                click={() => {
                  history.push({
                    pathname: `/league/${x._id}`
                  })
                }}
              >
                <Text>{x.name}</Text>
              </Clickable>
            </div>  
          ))}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    leagues: state.leagues
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getAll: () => {
      dispatch(getAll())
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Leagues)
)
