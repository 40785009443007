const seasons = (state = [], action) => {
  const { payload } = action

  switch (action.type) {
    case 'SAVE_SEASONS':
      return payload


    case 'CREATE_DIVISIONS':
      return state.map(x => {
        if(x._id !== payload.seasonId) return x

        return {
          ...x,
          divisions: payload.divisions
        }
      })

    case 'ADD_DIVISION':
      return state.map(x => {
        if(x._id !== payload.seasonId) return x

        return {
          ...x,
          divisions: [
            ...x.divisions,
            payload.division
          ]
        }
      })

    case 'DELETE_DIVISION':
      return state.map(x => {
        if(x._id !== payload.seasonId) return x

        return {
          ...x,
          divisions: x.divisions.filter((div, index) => index+1 !== x.divisions.length)
        }
      })

    case 'DRAG_PLAYER':
      return state.map(x => {
        if(x._id !== payload.seasonId) return x

        // it is the correct season. move player to the correct division
        return {
          ...x,
          divisions: x.divisions.map(division => {
            // add the user to new list
            if(division.name === payload.newList) {
              return {
                ...division,
                players: [
                  ...division.players,
                  { playerId: payload.playerId }
                ]
              }
            }

            // remove the user from old list
            if(division.name === payload.oldList) {
              return {
                ...division,
                players: division.players.filter(player => payload.playerId !== player.playerId)
              }
            }

            // not the old or new division for the player, return as is
            return division
          })
        }
      })

    case 'SAVE_SEASON':
      // if the season is in redux, replace it
      const season = state.find(x => x._id === payload._id)
      if(season) {
        return state.map(x => x._id === payload._id ? payload : x)
      }

      // there is no season in redux, just insert it
      return [
        payload,
        ...state
      ]

    case 'SAVE_SEASON_CHECK':
      return state.map(x => {
        if(x._id !== payload.seasonId) return x

        return {
          ...x,
          dataCheck: payload,
        }
      })

    case 'LOGOUT':
      return []

    default:
      return state
  }
}

export default seasons
