import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import playerReadjustments from "../graphql/queries/playerReadjustments";

const LeagueUserSkillReadjustment = ({ playerData, playerId }) => {
  const { loading, data, error, refetch } = useQuery(playerReadjustments, {
    variables: { playerId },
  });

  if (!data || !data.playerProgressLog) return <span>none</span>;
  return <span>{Math.round(data.playerProgressLog.previousValue*100)/100}</span>
}

export default LeagueUserSkillReadjustment;