const theme = {
  BLACK: '#2d2d2d',
  LIGHT_BLACK: 'rgb(87, 87, 87)',
  WHITE: 'rgb(242, 242, 242)',
  BLUE: '#6479FC',
  GREEN: '#00d885',
  ORANGE: 'rgb(224, 136, 38)',
  DARKBLUE: '#6479FC',
  GRAY: '#ddd',
}

export default theme
