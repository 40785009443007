import React from 'react'
import { withRouter } from 'react-router-dom'
//import { Button } from 'components'
import { Button } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { logout } from 'store/actions/user'

const LogoutButton = (props) => (
  <Button  
    onClick={() => {
      localStorage.removeItem('token')
      props.history.push({ pathname: '/' })
      props.logout()
    }} >
      Logout
  </Button>
)

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => {
      dispatch(logout())
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LogoutButton)
)
