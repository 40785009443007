import React from 'react'
import { withRouter } from 'react-router-dom'
import ObjectID from 'bson-objectid'
import { connect } from 'react-redux'
import { create } from 'store/actions/leagues'

import { Container, Button, Text } from 'components'
import CourtsSelect from '../components/common/CourtsSelect'
import { Input, Radio } from 'semantic-ui-react'
import UserRatingView from '../components/UserRatingView'
import UserRatingViewV2 from '../components/UserRatingViewV2'

class User extends React.Component {
  state = {
    // _id: ObjectID().toString(),
    // name: '',
    // slug: '',
    // club: false
  }


  create = () => {
    const { create } = this.props
    create(this.state)
  }

  render() {
    // const { _id, name, price, slug } = this.state;
    return(
      <Container itemsAlign='flex-start'>
        {/* <UserRatingView playerId={this.props.userId} /> */}
        <UserRatingViewV2 playerId={this.props.userId} />
      </Container>
    )
  }
}

// const mapStateToProps = state => {
//   return {}
// }

const mapStateToProps = (state, ownProps) => {
  const userId = ownProps.match.params.id
  return {
    userId,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    create: payload => {
      dispatch(create(payload))
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(User)
)
