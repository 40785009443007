import React from 'react';
import ReactQuill from 'react-quill';
import RichTextInputToolbar from './RichTextInputToolbar';
import 'react-quill/dist/quill.snow.css'; // Import the styles

const RichTextInput = ({ value, onChange }) => {
    const modules = {
        toolbar: {
            container: "#toolbar",
        }
    }
    const formats = [
        'bold', 'italic', 'underline', 'strike',
        'color', 'background',
        'header', 'blockquote',
        'list',
        'link', 'video'
    ]

    return (
        <>
            <RichTextInputToolbar />
            <ReactQuill
                value={value}
                onChange={onChange}
                modules={modules}
                formats={formats}
            />
        </>
    )
};


export default RichTextInput;
