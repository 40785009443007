import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, withRouter, NavLink } from 'react-router-dom'

import { Nav } from 'components'

import NewLeague from 'screens/NewLeague'
import Leagues from 'screens/Leagues'
import League from 'screens/league'
import User from 'screens/User'

class MainNavigation extends Component {
  componentWillMount() {
    // localStorage.removeItem('token')
    this.props.connect()
  }

  componentWillUnmount() {
    this.props.disconnect()
  }

  render() {
    const { location } = this.props

    return (
      <div className="ui container">
        <Nav />

        <Switch>
          <Route exact path='/' component={Leagues} />
          <Route path='/new-league' component={NewLeague} />
          <Route path='/league/:id' component={League} />
          <Route path='/user/:id' component={User} />
        </Switch>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { }
}

const mapDispatchToProps = dispatch => {
  return {
    connect: payload => {
      dispatch({type: 'WS_CONNECT'})
    },
    disconnect: payload => {
      dispatch({type: 'WS_DISCONNECT'})
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainNavigation)
)
