import { gql } from '@apollo/client';

const getDivisionGameWinPercentage = gql`
  query DivisionGameWinPercentage($playerIds: [MongoID!]!, $divisionId: MongoID!, $seasonId: MongoID!) {
    usersById(_ids: $playerIds) {
      _id
      divisionGameWinPercentage(seasonId: $seasonId, divisionId: $divisionId)
    }
  }
`;

export default getDivisionGameWinPercentage;
