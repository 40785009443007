import Api from 'lib/api'

const create = (payload) => {
  return dispatch => {
    Api.post('/seasons/create', payload, (err, res) => {
      if(err) return console.log('err', err)

      dispatch({
        type: 'SAVE_SEASON',
        payload: res
      })
    })
  }
}

export default create
