import styled from 'styled-components'

const ButtonContainer = styled.div`
  flex-direction: row;
  background-color: transparent;
  justify-content: space-between;
  align-self: center;
  width: 100%;
  margin: 20px 0;
  display: flex;
`

export default ButtonContainer
