import { gql } from '@apollo/client';
import { COMMUNICATION_FRAGMENT } from '../fragments/communication';

const divisionPlan = gql`
  query Communications($seasonId: MongoID) {
    communications(
      filter: { seasonId: $seasonId },
      sort: SENDAT_ASC
    ) {
     ...Communication
    }
  }
  ${COMMUNICATION_FRAGMENT}
`;

export default divisionPlan;
