const DEFAULT_LOCALE = 'en-GB';
const DEFAULT_OPTIONS = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
};  

const formatDate = (date, locale = DEFAULT_LOCALE, options = DEFAULT_OPTIONS) => {
    const formattedDate = date.toLocaleDateString(locale, options);
    return formattedDate;
}

export default formatDate;