import React, { useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import LocationSelect from './common/LocationSelect';
import LocationMultiSelect from './common/LocationMultiSelect';
import { Button } from 'semantic-ui-react';

const GET_LEAGUE = gql`
  query GetLeague($id: MongoID!) {
    leagueById(_id: $id) {
      _id
      name
      location {
        countryId
        stateId
        cityId
        placeIds
      }
    }
  }
`;

const UPDATE_LEAGUE = gql`
  mutation UpdateLeague($id: MongoID!, $record: UpdateByIdLeagueInput!) {
    leagueUpdateById(_id: $id, record: $record) {
      recordId
      record {
        _id
        name
        location {
          stateId
          countryId
          cityId
          placeIds  
        }
      }
    }
  }
`;

function LeagueEdit({ id }) {
  const [league, setLeague] = useState(null);
  const [countryId, setCountryId] = useState('');
  const [cityId, setCityId] = useState('');
  const [placeIds, setPlaceIds] = useState([]);
  const [stateId, setStateId] = useState('');

  const { loading, error, data } = useQuery(GET_LEAGUE, {
    variables: { id },
    onCompleted: (data) => {
      console.log(' MI DATA: ', data);
      setLeague(data.leagueById);
      setCountryId(data.leagueById.countryId);
      setCityId(data.leagueById.cityId);
      setPlaceIds(data.leagueById.placeIds);
      setStateId(data.leagueById.stateId)
    },
  });

  const [updateLeague] = useMutation(UPDATE_LEAGUE);

  const handleFormSubmit = (event) => {
    event.preventDefault();

    updateLeague({
      variables: {
        id: league._id,
        record: {
          location: {
            countryId,
            stateId,
            cityId,
            placeIds,
          }
        }
      },
    }).then((data) => {
      setLeague(data.data.updateLeague);
    });
  };

  console.log('HAY PLACES: ', placeIds)

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  if (!league) return null;

  console.log('LEAGUE PLACE IDS: ', league.location.placeIds)

  return (
    <div>
      <h1>{league.name}</h1>
      <LocationSelect type="COUNTRY" onSelect={(area) => area && setCountryId(area._id)} current={league.location.countryId} />
      {/* <br /> */}
      {/* <LocationSelect type="STATE" parent={countryId} onSelect={(area) => area ? setStateId(area._id) : setStateId(null)} current={league.location.stateId} /> */}
      <br />
      <LocationSelect type="CITY" parent={stateId ? stateId : countryId} onSelect={(area) => area ? setCityId(area._id) : setCityId(null)} current={league.location.cityId} />
      <br />
      <LocationMultiSelect type="PLACE" parent={cityId} onSelect={(areas) => setPlaceIds(areas)} current={league.location.placeIds} />
      <br />
      <Button onClick={handleFormSubmit}>
        Save
      </Button>
      <br /><br />
    </div>
  );
}

export default LeagueEdit;
