import React from 'react'
import styled from 'styled-components'
import { withRouter, NavLink } from 'react-router-dom'
import { Dropdown } from 'semantic-ui-react'

import { LogoutButton, Container } from 'components'
import UserHeaderBox from '../auth/UserHeaderBox'
import { defaultTimezone } from '../../lib/constants'

const NavWrap = styled.nav`
  height: 50px;
  box-shadow: 0 0 3px #aaa;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: white;
  zIndex: 1;
  padding: 0 20px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`

const Nav = ({ match }) => (
  <div className="ui text menu">
    <div className="item small">
      <div className="image">      
        <img className='ui tiny image' alt='logo' src="/logo_long_black.png"/>
      </div>       
    </div>
    <div className="item"><h3>Leagues Admin</h3></div>    
    <div className="item"><h5>Timezone: {defaultTimezone}</h5></div>    
    <div className="ui right dropdown item">
      <Dropdown text='More'>
        <Dropdown.Menu>
          <Dropdown.Item>
            <NavLink to={'/'}>Leagues</NavLink>
          </Dropdown.Item>  
          <Dropdown.Item>
            <NavLink to={'/new-league'}>New League</NavLink>
          </Dropdown.Item> 
        </Dropdown.Menu>
      </Dropdown>          
    </div>
    
    {/* <UserHeaderBox /> */}
    <LogoutButton />
  </div>
)

export default withRouter(Nav)
