import { gql } from '@apollo/client';

const courtsByName = gql`
  query CourtsByName($name: String) {
    courtPagination(
      filter: { search: $name },
      page: 1, 
      perPage: 20
    ) {
        items {
            _id
            name
            location {
                coordinates
            }
        } 
    }
  }
`;

export default courtsByName;
