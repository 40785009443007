import { gql } from '@apollo/client';

const playerSkillAssesment = gql`
  query PlayerSkillAssesment($playerId: MongoID) {
    skillAssesment(
      filter: { userId: $playerId },
    ) {
      _id
      userId
      comments,
    }
  }
`;

export default playerSkillAssesment;
