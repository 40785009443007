import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Dropdown, Confirm, Image, Header } from "semantic-ui-react";
import { Container, Clickable, Button, Text, Avatar } from "components";
import teamsByName from "../../graphql/queries/teamsByName";
const TeamsSelect = ({
  onSelect,
  useConfirm,
  confirmHeader,
  confirmContent,
  placeholder
}) => {
  const [name, setName] = useState();
  const { loading, data, error, refetch } = useQuery(teamsByName, {
    variables: { name },
    // onCompleted(response) {
    // },
  });
  const options = (data
    ? data.teamPagination.items.map((c) => ({
        key: c._id,
        value: c._id,
        text: c.name,
        ...c,
      }))
    : []).filter(s => s.captain);
  const [selected, setSelected] = useState(null);
  return (
    <>
      <Dropdown
        placeholder={placeholder || "Select a User"}
        search
        fluid
        // style={{width: '100%'}}
        selection
        onSearchChange={(e) => {
          setName(e.target.value);
        }}
        // onChange={(e, data) => {
        //   onSelect &&
        //     onSelect(data.options.find((opt) => opt.value === data.value));
        // }}
        // options={data ? data.teamPagination.items.map(c => ({ key: c._id, value: c._id, text: c.name, ...c })) : []}
      >
        <Dropdown.Menu>
          {options.map((item, ind) => {
            console.log('item ======', item);
            return (
            <Dropdown.Item
              content={
                <Clickable
                  onClick={() => {
                    useConfirm && setSelected(item);
                    !useConfirm && onSelect(item);
                  }}
                >
                   <Header as='h4' image>
                      <Image src={item.avatar} rounded size='mini' />
                      <Header.Content>
                        {item.name} Captain({item.captain.name})
                      </Header.Content>
                    </Header>
                </Clickable>
              }
            />
          )
          })}
        </Dropdown.Menu>
      </Dropdown>
      {useConfirm && (
        <Confirm
          open={!!selected}
          header={confirmHeader}
          content={confirmContent}
          onCancel={() => setSelected(null)}
          onConfirm={() => {
            onSelect(selected);
            setSelected(false);
          }}
        />
      )}
    </>
  );
};

export default TeamsSelect;
