import React from 'react'
import _ from 'lodash'
import ObjectID from 'bson-objectid'
import { connect } from 'react-redux'
import { create, getAll } from 'store/actions/seasons'
import { Input } from 'semantic-ui-react'
import { withRouter, NavLink, Switch, Route } from 'react-router-dom'
import DatePicker from '../../components/ZonedDatePicker'

import {
  Container,
  Clickable,
  Button,
  Text
} from 'components'

import Season from './Season'
import { addDays, addWeeks, subDays, parseISO, setHours, startOfWeek } from 'date-fns'
import Divisions from './Divisions'
import Waitlist from '../../components/Waitlist'
import Communications from './Communications'
import CourtsSelect from '../../components/common/CourtsSelect'
import LeagueEdit from '../../components/LeagueEdit'
import { zonedTimeToUtc } from 'date-fns-tz'
import { defaultTimezone } from '../../lib/constants'
class League extends React.Component{
  state = {
    _id: ObjectID().toString(),
    name: '',
    price: '',
    playOffWeeks: '',
    regularWeeks: '',
    courts: '',
    start: null,
    end: null,
    regStart: null,
    regEnd: null,
    midPoint: null
  }

  componentWillMount() {
    const { leagueId, getAll } = this.props
    getAll(leagueId)
  }

  // creates a season
  create = () => {
    const { create, league } = this.props
    const { _id, name, price, start, end, regStart, regEnd, midPoint, rules, info, courts = 0 } = this.state

    create({
      _id,
      name,
      price,
      league: league._id,
      userIds: [],
      start,
      end,
      regStart,
      regEnd,
      midPoint,
      rules,
      info,
      courts
    })

    this.setState({
      name: '',
      price: ''
    })
  }

  render() {
    const { league, seasons, match, history, location } = this.props
    const { _id, name, price, start, end, regStart, regEnd, midPoint, rules, info , regularWeeks, playOffWeeks, courts} = this.state

    if(!league) return null
    console.log(match.path);
    console.log(match.path.indexOf('divisions'));

    return (
      <Container flexDir='row'>
        <Route 
          render={({ location }) => {
            return !location.pathname.includes("divisions")
              ? (
                <Container maxWid='300px'>
                <h2>{league.name}</h2>
                <a target="_blank" href={`https://dashboard.momentumvolleyball.ca/dashboard/38-league-health?id=${league._id}`}>Open League Metrics</a>                
                {_.sortBy(seasons, ['start']).reverse().map(s => (
                  <NavLink
                    key={s._id}
                    to={`${match.url}/season/${s._id}`}
                  >
                    <Text>{s.name}</Text>
                  </NavLink>
                ))}
  
                <Container marg='20px auto' noFlex>
                  <h3>New Season:</h3>
                  <Input
                    placeholder='Name'              
                    onChange={e => this.setState({ name: e.target.value }) }
                    value={name}
                  />
                  <Input
                    placeholder='Price'
                    onChange={e => this.setState({ price: e.target.value }) }
                    value={price}
                  />
  
                  {/* TODO: do a radio (doubles singles) */}
                  Registration Start
                  <DatePicker
                    selected={this.state.regStart}
                    onChange={(date) => {
                      this.setState({
                      regStart: date, 
                      })
                    }}
                    dateFormat='dd/MM/yyyy, HH:mm'
                    timeFormat='HH:mm'
                    showTimeSelect
                  />
  
                  Registration end
                  <DatePicker
                    selected={this.state.regEnd}
                    onChange={(date) => this.setState({ regEnd: date }) }
                    dateFormat='dd/MM/yyyy'
                  />
  
                  Season Start
                  <DatePicker
                    selected={this.state.start}
                    onChange={(date) => {
                      // const start = zonedTimeToUtc(setHours(addDays(startOfWeek(startOfMonth(addWeeks(lastSeason.end, 1))), 2), 23), defaultTimeZone);
                      const midPoint = zonedTimeToUtc(setHours(addDays(startOfWeek(addWeeks(date, 5)), 1), 23), defaultTimezone);
                      const end = zonedTimeToUtc(setHours(startOfWeek(addWeeks(date, 8)), 23), defaultTimezone);                      
                      this.setState({
                      regEnd: zonedTimeToUtc(setHours(addDays(startOfWeek(date), 1), 23), defaultTimezone),
                      start: date,
                      midPoint,
                      end,
                      })
                    }}
                    dateFormat='dd/MM/yyyy, HH:mm'
                    timeFormat='HH:mm'
                    showTimeSelect              
                  />
  
                  Mid Point
                  <DatePicker
                    selected={this.state.midPoint}
                    onChange={(date) => this.setState({ midPoint: date }) }
                    dateFormat='dd/MM/yyyy'
                  />

                    {!!this.state.start && <>
                      Regular Weeks
                      <Input
                        type='number'
                        placeholder='Regular Weeks'
                        onChange={e => {
                          const val = Number(e.target.value);
                          const playOffVal = Number(this.state.playOffWeeks);
                          let add = 0;
                          const newState = {};
                          if (!isNaN(val)) {
                            newState.regularWeeks = Number(val);
                            add += newState.regularWeeks;
                          }
                          if (!isNaN(playOffVal)) {
                            // newState.regularWeeks = Number(val);
                            add += Number(playOffVal);
                          }
                          const endWeek = zonedTimeToUtc(setHours(addWeeks(start, add), 23), defaultTimezone);
                          this.setState({ ...newState, end: endWeek })
                        }}
                        value={regularWeeks}
                      />
                    </>}
                    {!!this.state.start && <>
                      PlayOff Weeks
                      <Input
                        type='number'
                        placeholder='Regular Weeks'
                        onChange={e => {
                          const val = Number(e.target.value);
                          const regularVal = Number(this.state.regularWeeks);
                          let add = 0;
                          const newState = {};
                          if (!isNaN(val)) {
                            newState.playOffWeeks = Number(val);
                            add += newState.playOffWeeks;
                          }
                          if (!isNaN(regularVal)) {
                            // newState.regularWeeks = Number(val);
                            add += Number(regularVal);
                          }
                          const endWeek = zonedTimeToUtc(setHours(addWeeks(start, add), 23), defaultTimezone);
                          this.setState({ ...newState, end: endWeek })
                        }}
                        value={playOffWeeks}
                      />
                    </>}
                     Courts
                     <Input
                        type='number'
                        placeholder='Number of courts'
                        onChange={e => {
                          if (!isNaN(Number(e.target.value))) {
                            this.setState({"courts":  Number(e.target.value)});
                          }
                        }}
                        value={courts}
                      />
                  Season End
                  <DatePicker
                    selected={this.state.end}
                    onChange={(date) => this.setState({ end: date }) }
                    dateFormat='dd/MM/yyyy'
                    disabled
                    // dateFormat='dd/MM/yyyy, HH:mm'
                    timeFormat='HH:mm'
                    showTimeSelect    
                  />
  
                  <Button
                    marg='20px 0'
                    click={this.create}
                    text='Create Season'
                    url={`${match.url}/season/${_id}/`}
                  />
                </Container>
              </Container>          
              )
              : null
          }}
        />
          <Switch>
            <Route exact path={`${match.path}`} key={location.pathname}>
              <Container>
                <LeagueEdit id={league._id} />
                <Waitlist players={league.waitList} />
              </Container>
            </Route>            
            <Route exact path={`${match.path}/season/:id`} key={location.pathname}>
              <Container>
                <Season/>
              </Container>
            </Route>
            <Route exact path={`${match.path}/season/:id/divisions`} key={location.pathname} component={Divisions}/>
            <Route exact path={`${match.path}/season/:id/games`} key={location.pathname} component={Divisions}/>
            <Route exact path={`${match.path}/season/:id/communications`} key={location.pathname} >
              <Container>
                <Communications/>
              </Container>
            </Route>
          </Switch>        
      </Container>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const leagueId = ownProps.match.params.id
  return {
    leagueId,
    league: state.leagues.find(x => x._id === leagueId),
    seasons: state.seasons.filter(x => x.league && x.league._id === leagueId)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    create: payload => {
      dispatch(create(payload))
    },
    getAll: payload => {
      dispatch(getAll(payload))
    },
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(League)
)
