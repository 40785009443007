import { gql } from '@apollo/client';

const USER = gql`
  query user {
    user {
      _id
      firstName
      lastName
      avatar
    }
  }
`;

export default USER;
