import React from "react";
import { Table, Popup, Icon } from 'semantic-ui-react';
import getDivisionGameWinPercentage from "../graphql/queries/getDivisionGameWinPercentage";

const DECIMALS_NUMBER = 2;
const ADMIN_URL = process.env.REACT_APP_ADMIN_URL || "http://localhost:3001"

const isValidPercentage = (percentage) => percentage >= 0.25 && percentage <= 0.85;

const getColorByPercentage = (percentage) => {
    const style = {};

    if (percentage == null || isValidPercentage(percentage)) return style;

    style.fontWeight = 'bold';

    if (percentage > 0.85 || percentage < 0.25) {
        style.color = 'red'
    }

    return style;
}

const matchesPlayedPopUpContent = (gamesData) => (
    <>
        {
            gamesData && gamesData.length > 0 ? gamesData.map((game, index) => (
                <div key={index}>
                    <span><strong>Game Id: </strong><a target="_blank" href={`${ADMIN_URL}/#/game/${game.gameId}/show`}>{game.gameId}</a></span>
                    <br />
                    <span>
                        <strong>Percentage of games won: </strong>
                        <span style={getColorByPercentage(game.percentageOfGamesWon)}>{`${(game.percentageOfGamesWon * 100).toFixed(DECIMALS_NUMBER)}%`}</span>
                    </span>
                    <hr />
                </div>
            )) : <span>No data available</span>
        }
    </>
);


const DivisionGameWinPercentage = ({ player, divisionGameWinPercentage }) => {
    const {
        percentageWon,
        gamesData,
        matchesPlayed,
        matchesWonOver85,
        matchesWonBelow25
    } = divisionGameWinPercentage;

    const percentageStyle = getColorByPercentage(percentageWon);

    return (
        <Table.Row className={percentageWon != null && !isValidPercentage(percentageWon) ? "error" : (matchesWonOver85 > 0 || matchesWonBelow25 > 0) ? "warning": ""}>
            <Table.Cell>{player.name}</Table.Cell>
            <Table.Cell style={percentageStyle}>{percentageWon != null ? `${(percentageWon * 100).toFixed(DECIMALS_NUMBER)}%` : '-'}</Table.Cell>
            <Table.Cell style={{color: matchesWonOver85 > 0 ? 'red' : 'black' }}>{matchesWonOver85 != null ? matchesWonOver85 : '-'}</Table.Cell>
            <Table.Cell style={{color: matchesWonBelow25 > 0 ? 'red' : 'black' }}>{matchesWonBelow25 != null ? matchesWonBelow25 : '-'}</Table.Cell>
            <Table.Cell>
                {
                    matchesPlayed != null ? <Popup
                        hoverable
                        header={`Matches Played: ${matchesPlayed}`}
                        content={() => matchesPlayedPopUpContent(gamesData)}
                        trigger={<span style={percentageStyle}>{matchesPlayed} <Icon size="tiny" name="info circle"/></span>}
                    /> : '-'
                }
                
            </Table.Cell>

        </Table.Row>
    );
}

export default DivisionGameWinPercentage;