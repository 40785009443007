import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import {
  getSeason,
} from 'store/actions/seasons'
import { withRouter, NavLink } from 'react-router-dom'
import DivisionsPanes from '../../components/DivisionsPanes'
import { Container, Loader } from 'semantic-ui-react'
import SeasonCommunicationsTable from '../../components/SeasonCommunicationsTable'

class Communications extends React.Component{
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidUpdate(prevProps, prevState) {
    const { seasonId, season } = this.props;
        
    if (prevProps.seasonId !== seasonId || !season.users) {
      this.fetchSeason();
    }   
  }    
 
  componentWillMount() {
    this.fetchSeason();
  }

  fetchSeason = async () => {
    const { seasonId, dispatch } = this.props;
    await dispatch(getSeason(seasonId));    
  }

  reload() {
    const history = useHistory()
    history.go(0)
  }

  render() {
    const { season } = this.props
      
    if(!season || !season.divisions || !season.users) return <Loader active inline='centered' />
  
    return (
      <>
        <h2>All Season communications:</h2>
        <SeasonCommunicationsTable season={season} refetchSeason={this.fetchSeason} />
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const seasonId = ownProps.match.params.id

  return {
    seasonId,
    season: state.seasons.find(s => s._id === seasonId)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getSeason: payload => {
      dispatch(getSeason(payload))
    },
    dispatch,
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Communications)
)
