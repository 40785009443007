import React, { useRef, useState } from "react";
import { Grid, Table, Header, GridRow } from "semantic-ui-react";
import DivisionGameWinPercentage from "./DivisionGameWinPercentage";
import getDivisionGameWinPercentage from "../graphql/queries/getDivisionGameWinPercentage";
import { useLazyQuery } from "@apollo/client";
import useIsInViewport from "../lib/hooks/useIsInViewport";

const DivisionStatsTable = ({ division, players, seasonId }) => {

    const ref = useRef(null);

    const [hasAppearedOnce, setHasAppearedOnce] = useState(false);
    const isInViewport = useIsInViewport(ref);

    const divisionPlayers = division.players;
    const divisionPlayersIds = divisionPlayers.map(player => player.playerId);
    const variables = { playerIds: divisionPlayersIds, divisionId: division._id, seasonId }

    const [getData, { data, error }] = useLazyQuery(getDivisionGameWinPercentage);

    if (isInViewport && !hasAppearedOnce) {
        getData({ variables });
        setHasAppearedOnce(true);
    }

    const divisionGameWinPercentages = data ? data.usersById : [];

    return (
        <Grid>
            <GridRow>
                <Grid.Column>
                    <Header as='h3' dividing>
                        <span ref={ref}>{division.name}</span>
                    </Header>
                </Grid.Column>
            </GridRow>
            <GridRow>
                <Grid.Column width={16} >
                    <Table celled singleLine>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Player</Table.HeaderCell>
                                <Table.HeaderCell>Total game percentage won</Table.HeaderCell>
                                <Table.HeaderCell># matches won &gt; 85%</Table.HeaderCell>
                                <Table.HeaderCell># matches won &lt; 25%</Table.HeaderCell>
                                <Table.HeaderCell># matches played</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        {(error || !data) ?
                            (
                                <Table.Body>
                                    {
                                        division.players.map(player => {
                                            const playerData = players.find(p => p._id === player.playerId)

                                            return (
                                                <Table.Row>
                                                    <Table.Cell>{playerData.name}</Table.Cell>
                                                    <Table.Cell>-</Table.Cell>
                                                    <Table.Cell>-</Table.Cell>
                                                    <Table.Cell>-</Table.Cell>
                                                    <Table.Cell>-</Table.Cell>
                                                </Table.Row>
                                            );
                                        })
                                    }
                                </Table.Body>
                            ) :
                            < Table.Body >
                                {
                                    division.players.map(player => {
                                        const playerData = players.find(p => p._id === player.playerId)
                                        const divisionGameWinPercentage = divisionGameWinPercentages.find(p => p._id === player.playerId).divisionGameWinPercentage;
                                        return (
                                            <DivisionGameWinPercentage key={player.playerId} player={playerData} divisionGameWinPercentage={divisionGameWinPercentage} />
                                        )
                                    })
                                }
                            </Table.Body>
                        }
                    </Table>
                </Grid.Column>
            </GridRow>
        </Grid >
    )
};

export default DivisionStatsTable