import { gql } from '@apollo/client';

const leagueEnrollmentByPlayerAndSeason = gql`
  query LeagueEnrollmentByPlayerAndSeason($playerId: MongoID, $seasonId: MongoID) {
    leagueEnrollment(
      filter: {
        userId: $playerId
        seasonId: $seasonId
      }
    ) {
      source
      couponId
      paymentId
      payment {
        annual
        amount
        couponId
        coupon {
          code
        }
        logs {
          data
        }
        data
      }
    }
  }
`;


export default leagueEnrollmentByPlayerAndSeason;