import React from 'react'
import MaterialIcon from 'material-icons-react'
import theme from 'lib/theme'

const Icon = (props) => (

  // Added dimensions for smaller/bigger icons(like checklist icons)
  <div
    style={{
      width: props.dimensions ? props.dimensions : 22,
      height: props.dimensions ? props.dimensions : 22,
      cursor: 'pointer',
      margin: props.marg && props.marg,
    }}
    key={props.type}
    onClick={props.click && props.click}
  >
    <MaterialIcon icon={props.type} color={props.color || theme.CYAN}/>
  </div>
)

export default Icon
