import { gql } from '@apollo/client';

const allGamesPlayedByUser = gql`
  query AllGamesPlayedByUser($playerId: String) {
    gamePagination(
      filter: { player: $playerId, status: "Played", leagueOnly: true },
      page: 1, 
      perPage: 1000,
      sort: STARTTIME_ASC
    ) {
        items {
            _id
            glickoResults
            ratingBonusPts
            postMatchRatings
            startTime
            createdAt
            winnerId
            users {
              data {
                _id
                name
                leagueEntrySkillLevel
                skillLevel
                createdAt
              }
            }
        } 
    }
  }
`;

export default allGamesPlayedByUser;
