import { useLazyQuery } from "@apollo/client";
import React, { useRef, useState } from "react";
import { Table } from 'semantic-ui-react';
import leagueEnrollmentByPlayerAndSeason from "../graphql/queries/leagueEnrollmentByPlayerAndSeason";
import { convertCurrenyToSymbol } from '../lib/currencyToSymbol';
import { paymentPaidDataPipeline } from '../lib/paymentPaidDataPipeline';
import useIsInViewport from "../lib/hooks/useIsInViewport";

const LeagueUserPayment = ({ playerId, season }) => {
    
    const ref = useRef(null);

    const [hasAppearedOnce, setHasAppearedOnce] = useState(false);
    const isInViewport = useIsInViewport(ref);

    const variables = { playerId, seasonId: season._id };

    const [getData, { data, error }] = useLazyQuery(leagueEnrollmentByPlayerAndSeason);

    if (isInViewport && !hasAppearedOnce) {
        getData({ variables });
        setHasAppearedOnce(true);
    }
    
    if (error || !(data && data.leagueEnrollment)) {
        return (
        <>
            <Table.Cell><span ref={ref}>-</span></Table.Cell>
            <Table.Cell>-</Table.Cell>
        </>
        );
    }

    const enrollment = data.leagueEnrollment;
    const payment = enrollment && enrollment.payment ? paymentPaidDataPipeline(enrollment.payment) : null;
    const paidData = payment && payment.paidData ? payment.paidData : null;
    const paymentTypeInfo = {
        fee: `${convertCurrenyToSymbol(season.currency)}0.00`,
        type: 'Full Fee'
    };

    if (enrollment && enrollment.source === 'Entry_Fee' && paidData && paidData.data) {
        const amount = paidData.data.object.amount / 100;
        paymentTypeInfo.fee = `${convertCurrenyToSymbol(season.currency)}${amount.toFixed(2)}`;
    }
    
    if (payment && payment.couponId) {
        const coupon = payment.coupon.code ? payment.coupon.code : "-"; 
        paymentTypeInfo.type = `Promo: ${coupon}`;
    } else if (enrollment && enrollment.source !== 'Entry_Fee') {
        paymentTypeInfo.type = enrollment.source.replace(/_/g, ' ');
    }

    return (
        <>
            <Table.Cell><small ref={ref}>{paymentTypeInfo.fee}</small></Table.Cell>
            <Table.Cell><small>{paymentTypeInfo.type}</small></Table.Cell>
        </>
    );
}

export default LeagueUserPayment;