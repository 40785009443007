import React from 'react'
import { withRouter } from 'react-router-dom'
import ObjectID from 'bson-objectid'
import { connect } from 'react-redux'
import { create } from 'store/actions/leagues'

import { Container, Button, Text } from 'components'
import CourtsSelect from '../components/common/CourtsSelect'
import { Input, Radio } from 'semantic-ui-react'

class NewLeague extends React.Component{
  state = {
    _id: ObjectID().toString(),
    name: '',
    slug: '',
    club: false
  }

  create = () => {
    const { create } = this.props
    create(this.state)
  }

  render() {
    const { _id, name, price, slug } = this.state;
    return(
      <Container maxWid='600px' itemsAlign='flex-start'>
        <Input
          placeholder='Name'
          onChange={(e) => this.setState({ name: e.target.value }) }
          value={name}
        />
        <Input
          placeholder='Slug'
          onChange={(e) => this.setState({ slug: e.target.value }) }
          value={slug}
        />        


        <Container marg='20px 0' flexDir='row'>        
          <Radio toggle label="Private Club League" onChange={() => this.setState({ club: !this.state.club }) }/>          
{/* 
          <Text>No</Text>
          <input name='club' type='radio' value={false} onChange={() => this.setState({ club: false }) }/>

          <Text>Yes</Text>
          <input name='club' type='radio' value={true} onChange={() => this.setState({ club: true }) }/> */}
        </Container>

        <Container flexDir='row' noFlex marg='0 0 30px'>
          <CourtsSelect onSelect={(court) => {
            console.log(court);
            this.setState({ courtId: court._id, coordinates: court.location.coordinates });
            }
          }/>
        </Container>

        <Button
          text='Create'
          click={this.create}
          url={`/league/${_id}/`}
        />
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    create: payload => {
      dispatch(create(payload))
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewLeague)
)
