import React, { useState } from "react";
import { GridRow, Header, Image, Table } from "semantic-ui-react";

const Waitlist = ({ players }) => {
    return (
      <>
        <GridRow><h2>Current Waitlist</h2><br/></GridRow>
        <GridRow centered>
          <Table sortable celled collapsing basic>      
            <Table.Body>
              {players.map((player, index) => (
                <Table.Row key={player._id}>
                  <Table.Cell>{index + 1}</Table.Cell>
                  <Table.Cell>
                    <Header as='h4' image>
                      <Image src={player.avatar} rounded size='mini' />
                      <Header.Content>
                        {player.name}
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </GridRow>    
      </>  
    )
}

export default Waitlist;