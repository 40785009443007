import { gql, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Dropdown } from "semantic-ui-react";
import geographicAreaByName from "../../graphql/queries/geographicAreaByName";

const LocationMultiSelect = ({ onSelect, type, parent, current }) => {
    const [name, setName] = useState('');
    const [currentLocationsIds, setCurrentLocationsIds] = useState([]);

    const GET_LOCATIONS = gql`
    query GetLocations($ids: [MongoID!]!) {
        geographicAreasById(_ids: $ids) {
            _id
            name {
                en_GB
            }
        }
    }`;

    const { data: currentLocations } = useQuery(GET_LOCATIONS, {
        variables: { ids: current },
        onCompleted(response) {
            setCurrentLocationsIds(response.geographicAreasById.map(geoArea => geoArea._id));
        },
    });

    const { loading, data, error, refetch } = useQuery(geographicAreaByName, {
        variables: { type, name, parent },
        // onCompleted(response) {
        // },
    });

    return (
        <Dropdown
            placeholder={type}
            search
            fluid
            selection
            clearable
            multiple
            value={currentLocationsIds}
            onSearchChange={(e) => {
                setName(e.target.value);
                // refetch({ search: e.target.value})
            }}
            onChange={(e, data) => {
                if (data.value) {
                    const options = data.options.filter(opt => data.value.includes(opt.value)).map(opt => opt.value);
                    setCurrentLocationsIds(options);
                    onSelect && onSelect(options);
                } else {
                    setCurrentLocationsIds([]);
                    onSelect && onSelect([]);
                }

            }}
            options={data ? data.geographicAreaPagination.items.map(c => ({ key: c._id, value: c._id, text: c.name.en_GB, ...c })) : []}
        />
    )
}

export default LocationMultiSelect;