import React from 'react'
import { Text } from '../'
import theme from 'lib/theme'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

const StyledButton = styled.button`
  background-color: ${props => props.color || theme.GREEN};
  border: ${props => props.color ? '1px solid ' + theme.CYAN : '0 none'};
  padding: ${props => props.pad ? props.pad : '15px'};
  cursor: pointer;
  align-items: center;
  border-radius: 8px;
  justify-content: center;

  width: 100%;
  transition: background-color 0.3s ease;
  margin: ${props => props.marg && props.marg};
`
const Button = (props) => (
  <StyledButton
    color={props.color || undefined}
    marg={props.marg}
    onClick={() => {
      // if there is a click function passed, do it
      props.click && props.click()

      if(props.url) {
        // go to the given URL
        return props.history.push({
          pathname: props.url,
          state: props.params
        })
      }

      // sometimes there is a click function, like saving data
      // and we need to go back. in those cases props containt back: true
      if(props.back) props.history.goBack()
    }}
  >
    <Text col={props.color ? theme.CYAN : 'white'}>{props.text}</Text>
  </StyledButton>
)

export default withRouter(Button)
