import Api from 'lib/api'

const updatePlayerLeagueSkill = async ({ playerId, skill, division, seasonId }) => {
  //return dispatch => {
  const res = await Api.postAsync(`/users/${playerId}/leagueSkill`, { skill, division, seasonId });
  return res.data;
  //}
}

export default updatePlayerLeagueSkill;
