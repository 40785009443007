import Api from 'lib/api'

const deleteDivision = (payload) => {
  return dispatch => {
    Api.post('/seasons/deleteDivision', payload, (err, res) => {
      if(err) return console.log('err', err)

      dispatch({
        type: 'DELETE_DIVISION',
        payload
      })
    })
  }
}

export default deleteDivision
