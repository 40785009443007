import { gql } from '@apollo/client';

const divisionPlan = gql`
  query DivisionPlan($seasonId: MongoID) {
    divisionPlans(
      filter: { seasonId: $seasonId },
      sort: CREATEDAT_DESC
    ) {
      _id
      seasonId,
      messages,   
      players {
        player{
          _id
          avatar
          name
          spinRating
          leagueEntrySkillLevel
          skillLevel
        }
        status
        previousSeasonName,
        previousStatus,
        previousDivision,
        newDivision,
        override,
        pastSeasonGamePercentage,
        ratingOffset
      }
      teams {
        team{
          _id
          avatar
          name
        }
        status
        previousSeasonName,
        previousStatus,
        previousDivision,
        newDivision,
        override,
        pastSeasonGamePercentage,
        ratingOffset
      }
    }
  }
`;

export default divisionPlan;
