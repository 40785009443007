import Api from 'lib/api'

const dragPlayer = (payload) => {
  return dispatch => {
    dispatch({
      type: 'DRAG_PLAYER',
      payload
    })

    Api.post('/seasons/dragPlayer', payload, (err, res) => {
      if(err) return console.log('err', err)
    })
  }
}

export default dragPlayer
