import Api from 'lib/api'

const addDivision = (payload) => {
  return dispatch => {
    Api.post('/seasons/addDivision', payload, (err, res) => {
      if(err) return console.log('err', err)

      dispatch({
        type: 'ADD_DIVISION',
        payload
      })
    })
  }
}

export default addDivision
