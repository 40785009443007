import React from 'react'
import styled from 'styled-components'

const StyledInput = styled.input`
  font-size: ${props => props.size ? props.size : '22px'};
  width: 100%;
  text-decoration: ${props => props.decoration ? props.decoration : 'none'};
  font-style: ${props => props.sty ? props.sty : 'normal'};
`

const Input = (props) => (
  <StyledInput
    placeholder={props.placeholder}
    size={props.size}
    bg={props.bg}
    col={props.col}
    onChange={(e) => {
      props.onChangeText(e.target.value)
    }}
    value={props.value}
    autoFocus={props.autoFocus}
    maxLength={props.maxLen}
    decoration={props.decoration}
    sty={props.sty}
  />
)

export default Input
