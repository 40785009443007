const leagues = (state = [], action) => {
  const { payload } = action

  switch (action.type) {
    case 'SAVE_LEAGUES':
      return payload

    case 'SAVE_LEAGUE':
      // if the league is in redux, replace it
      const league = state.find(x => x._id === payload._id)
      if(league) {
        return state.map(x => x._id === payload._id ? payload : x)
      }

      // there is no league in redux, just insert it
      return [
        payload,
        ...state
      ]

    case 'CREATE_LEAGUE':
      return [
        ...state,
        payload
      ]

    case 'LOGOUT':
      return []

    default:
      return state
  }
}

export default leagues
