import Api from 'lib/api'

const runGlicko = () => {
  return dispatch => {
    Api.post('/seasons/runGlicko', {}, (err, res) => {
      if(err) return console.log('err', err)
    })
  }
}

export default runGlicko;
