import { gql } from '@apollo/client';

export const COMMUNICATION_FRAGMENT = gql`
  fragment Communication on Communication {
    _id
      createdAt,
      target,
      category,
      type,
      attempts,
      status,
      statusUrl,
      sendAt,
      sentAt,
      cancelledAt,
      pushTitle,
      pushMessage,
      playerId,  
      seasonId,
      leagueId,
      errorMsg
  }
`;
