import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import playerNotifications from "../graphql/queries/playerNotifications";
import seasonGamesPlayedByUser from "../graphql/queries/seasonGamesPlayedByUser";

const LeagueUserWarningsSent = ({ playerId, seasonId }) => {
  const { loading, data, error, refetch } = useQuery(playerNotifications, {
    variables: { seasonId: seasonId, playerId },
  });

  if (!data) return null;
  return <span>{data.notifications.length}</span>
}

export default LeagueUserWarningsSent;