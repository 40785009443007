import React from 'react'

// redux store
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from '../store'
import { setContext } from '@apollo/client/link/context';
// router
import { BrowserRouter as Router } from 'react-router-dom'

import Navigator from './Auth'
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
import { createHttpLink } from 'apollo-link-http';

const authLink = setContext(async (_, { headers }) => {
      // get the authentication token from local storage if it exists
      const token = localStorage.getItem('token');
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : '',
        },
      };
    });    

    const httpLink = createHttpLink({
      uri: `${process.env.REACT_APP_API_URL}/graphqlmom`,
    });

    const defaultOptions = {};
    const client = new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache(),
        defaultOptions,
    });

const Root = () => (
  <ApolloProvider client={client}>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router>
          <Navigator />
        </Router>
      </PersistGate>
    </Provider>
  </ApolloProvider>
)

export default Root
