import { gql } from '@apollo/client';

const seasonGamesPlayedByUser = gql`
  query SeasonGamesPlayedByUser($seasonId: MongoID, $playerId: String) {
    gamePagination(
      filter: { seasonId: $seasonId, player: $playerId, status: "Played" },
      page: 1, 
      perPage: 100
    ) {
        items {
            _id
        } 
    }
  }
`;

export default seasonGamesPlayedByUser;
