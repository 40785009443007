import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Login from 'screens/Login'

const NotUser = () => (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route path="/login" component={Login} />
    </Switch>
)

export default NotUser
