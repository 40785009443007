import styled, { keyframes, css } from 'styled-components'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const Container = styled.div`
  flex-direction: ${props => props.flexDir ? props.flexDir : 'column' };
  flex: ${props => !props.noFlex && 1};
  flex-wrap: ${props => props.wrapFlex && 'wrap'};
  display: ${props => props.block ? 'block' : 'flex' };
  border-radius: ${props => props.hasRadius ? props.hasRadius : undefined};
  align-items: ${props => props.itemsAlign ? props.itemsAlign : 'center' };
  align-self: ${props => props.selfAlign ? props.selfAlign : 'stretch' };
  margin: ${props => props.marg ? props.marg : 0 };
  padding: ${props => props.pad ? props.pad : 0 };
  box-shadow: ${props => props.shadow && '0 0 3px #aaa'};
  border-bottom: ${props => props.borderBottom && '1px solid #ddd'};
  justify-content: ${props => props.contentJustify ? props.contentJustify : 'flex-start'};
  width: ${props => props.wid ? props.wid : 'auto'};
  background-color: ${props => props.bg ? props.bg : 'transparent'};
  min-height: ${props => props.minHeight && props.minHeight};
  max-height: ${props => props.maxHeight && props.maxHeight};
  max-width: ${props => props.maxWid && props.maxWid};
  min-width: ${props => props.minWid && props.minWid};
  height: ${props => props.ht ? props.ht : 'auto'};
  overflow-y: ${props => props.scrollCont && 'auto'};
  overflow-x: ${props => props.scrollX && 'auto'};
  transition: padding 0.3s, border-radius 0.3s, background-color 0.3s;

  ${props => props.fade &&
    css`
      animation: ${fadeIn} 0.2s linear;
    `
  }

  ${props => props.pos &&
    css`
      position: ${props.pos};
      right: ${props.posRight};
      left: ${props.posLeft};
      top: ${props.posTop};
      bottom: ${props.posBottom};
      z-index: ${props.indexZ};
    `
  }
`

export default Container
