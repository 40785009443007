import React, { useState } from "react";
import _ from "lodash";
import {
    Header,
    Table,
    Image,
    Input,
    Button,
    Confirm,
    Message,
    GridRow,
    Popup,
    Checkbox,
    GridColumn,
} from "semantic-ui-react";
import makeDivisions from "../store/actions/seasons/makeDivisions";
import createSeasonRatingAdjustment from "../store/actions/seasons/createSeasonRatingAdjustment";
import updatePlayerLeagueSkill from "../store/actions/seasons/updatePlayerLeagueSkill";
import { getSeason } from "store/actions/seasons";
import { useDispatch } from "react-redux";
import moveLatePlayerToLiveSeason from "../store/actions/seasons/moveLatePlayerToLiveSeason";
import removePlayerFromSeason from "../store/actions/seasons/removePlayerFromSeason";
import UsersSelect from "./common/UsersSelect";
import addPlayerToSeason from "../store/actions/seasons/addPlayerToSeason";
import { Container as SContainer, Text } from "components";
import TeamsSelect from "./common/TeamsSelect";
import addTeamToSeason from "../store/actions/seasons/addTeamToSeason";
import removeTeamFromSeason from "../store/actions/seasons/removeTeamFromSeason";

function reducer(state, action) {
    switch (action.type) {
        case "CHANGE_SORT":
            if (state.sortColumn === action.sortColumn) {
                return {
                    ...state,
                    data: state.data.slice().reverse(),
                    sortDirection:
                        state.sortDirection === "ascending"
                            ? "descending"
                            : "ascending",
                };
            }

            return {
                sortColumn: action.sortColumn,
                data: _.sortBy(state.data, [action.sortColumn]),
                // data: state.data,
                sortDirection: "ascending",
            };
        case "UPDATE_PLAYERS":
            return {
                ...state,
                data: action.data,
            };
        default:
            throw new Error();
    }
}

function buildPlayersTable(season) {
    const playersTable = [];
    if (season.users) {
        for (const player of season.users) {
            const divPlayer = { ...player, division: -1 };
            for (const [divIndex, division] of season.divisions.entries()) {
                for (const p of division.players) {
                    if (p.playerId === player._id) {
                        divPlayer.division = divIndex + 1;
                        break;
                    }
                }
            }
            playersTable.push(divPlayer);
        }
    }
    return playersTable;
}

function buildPlayingTeamsTable(season) {
    const playingTeamsTable = [];
    if (season.teamIds) {
        for (const team of season.teamIds) {
            const divTeam = { ...team, division: -1 };
            for (const [divIndex, division] of season.divisions.entries()) {
                for (const p of division.teams) {
                    if (p.teamId === team._id) {
                        divTeam.division = divIndex + 1;
                        break;
                    }
                }
            }
            playingTeamsTable.push(divTeam);
        }
    }
    return playingTeamsTable;
}


const SeasonPlayingTeamsTable = ({ season }) => {
    const [showInitConfirm, setShowInitConfirm] = useState(false);
    const [showInitConfirmOpts, setShowInitConfirmOpts] = useState(false);
    const [divisionSize, setDivisionSize] = useState(6);
    const [showUpdateConfirm, setShowUpdateConfirm] = useState(false);
    const [showRatingAdjustmentConfirm, setShowRatingAdjustmentConfirm] =
        useState(false);
    const [includeAllLeagues, setIncludeAllLeagues] = useState(false);
    const [resMessage, setResMessage] = useState();
    const [tableModified, setTableModified] = useState(false);
    const [playerToRemove, setPlayerToRemove] = useState();
    const [teamToRemove, setTeamToRemove] = useState();
    const [loading, setLoading] = useState(false);
    const [initialiseDivisionMessage, setInitialiseDivisionMessage] =
        useState("");
    const reduxDispatch = useDispatch();

    const [state, dispatch] = React.useReducer(reducer, {
        sortColumn: null,
        data: buildPlayingTeamsTable(season),
        sortDirection: null,
    });

    React.useEffect(() => {
        dispatch({
            type: "UPDATE_PLAYERS",
            data: buildPlayingTeamsTable(season),
        });
    }, [season]);


    const { sortColumn, data, sortDirection } = state;

    const updatePlayersData = (index, value) => {
        const pData = [...data];
        if (pData[index].division !== value) {
            pData[index] = { ...pData[index], division: value };
            dispatch({ type: "UPDATE_PLAYERS", data: pData });
            setTableModified(true);
        }
    };

    const updateDivisionPlanData = async () => {
        setLoading(true);
        const reqData = data
            .map((d) => ({ _id: d._id, division: parseInt(d.division) }))
            .filter((d) => d.division > 0);
        try {
            const res = await makeDivisions({
                seasonId: season._id,
                data: reqData,
            });
            setResMessage({
                message: "Success",
                checkMessages: res.messages ? res.messages : [],
            });
            // this.setState({ message: 'Success' });
        } catch (error) {
            setResMessage({
                message: "There was an error updating the division plan:",
                checkMessages: error.response ? [error.response.data] : [],
            });
            // this.setState({ message: 'Something went wrong' });
        }
        setLoading(false);
        // setTimeout(() => {
        //   this.setState({ message: null })
        // }, 2000)
    };

    const initDivisions = async (persist = true) => {
        setLoading(true);
        try {
            setInitialiseDivisionMessage("Please wait...");
            const res = await makeDivisions({
                seasonId: season._id,
                data: null,
                persist,
                divisionSize,
            });
            setResMessage({
                message: "Success",
                checkMessages: res.messages ? res.messages : [],
            });
            // this.setState({ message: 'Success' });
        } catch (error) {
            console.log("---errr----", error);
            setResMessage({
                message: "There was an error initializing the divisions:",
                checkMessages: error.response ? [error.response.data] : [],
            });
            // this.setState({ message: 'Something went wrong' });
        }
        if (persist) {
            setShowInitConfirm(false);
        } else {
            setShowUpdateConfirm(false);
        }
        setInitialiseDivisionMessage("");
        setLoading(false);
        // setTimeout(() => {
        //   this.setState({ message: null })
        // }, 2000)
    };

    const createPastSeasonRatingAdjustment = async (data) => {
        try {
            const res = await createSeasonRatingAdjustment({
                seasonId: season._id,
                data,
            });
            setResMessage({
                message: "Success",
                checkMessages: res.messages ? res.messages : [],
            });
        } catch (error) {
            setResMessage({
                message: "There was an error creating the rating adjustments:",
                checkMessages: error.response ? [error.response.data] : [],
            });
        }
    };

    const overridePlayerSkill = async (playerId, skillOverride) => {
        // console.log("Will update ", {...playersLeagueSkills, playerId: skillOverride});
        try {
            await updatePlayerLeagueSkill({ playerId, skill: skillOverride });
            // setShowConfirm(false);
            // setResMessage("Success")
            // reduxDispatch(getSeason(season._id));
            // this.setState({ message: 'Success' });
        } catch {
            // setResMessage("There was an error resetting the divisions.")
            // this.setState({ message: 'Something went wrong' });
        }
        // setTimeout(() => {
        //   this.setState({ message: null })
        // }, 2000)
    };

    const moveToLive = async (playerId) => {
        // console.log("Will update ", {...playersLeagueSkills, playerId: skillOverride});
        try {
            await moveLatePlayerToLiveSeason({
                seasonId: season._id,
                data: { playerId },
            });
            // setShowConfirm(false);
            // setResMessage("Success")
            reduxDispatch(getSeason(season._id));
            // this.setState({ message: 'Success' });
        } catch {
            setResMessage({ message: "There was an error moving the player." });
            // this.setState({ message: 'Something went wrong' });
        }
        // setTimeout(() => {
        //   this.setState({ message: null })
        // }, 2000)
    };

    const removePlayer = async () => {
        // console.log("Will update ", {...playersLeagueSkills, playerId: skillOverride});
        try {
            await removePlayerFromSeason({
                seasonId: season._id,
                data: { playerId: playerToRemove._id },
            });
            setPlayerToRemove(null);
            // setResMessage("Success")
            reduxDispatch(getSeason(season._id));
            // this.setState({ message: 'Success' });
        } catch {
            setResMessage({
                message: "There was an error removing the player.",
            });
            // this.setState({ message: 'Something went wrong' });
        }
        // setTimeout(() => {
        //   this.setState({ message: null })
        // }, 2000)
    };

    const removeTeam = async () => {
        // console.log("Will update ", {...playersLeagueSkills, playerId: skillOverride});
        try {
            await removeTeamFromSeason({
                seasonId: season._id,
                data: { teamId: teamToRemove._id },
            });
            setTeamToRemove(null);
            // setResMessage("Success")
            reduxDispatch(getSeason(season._id));
            // this.setState({ message: 'Success' });
        } catch {
            setResMessage({
                message: "There was an error removing the player.",
            });
            // this.setState({ message: 'Something went wrong' });
        }
        // setTimeout(() => {
        //   this.setState({ message: null })
        // }, 2000)
    };

    const addTeam = async (teamToAdd) => {
        console.log("adding", teamToAdd);
        try {
            await addTeamToSeason({
                seasonId: season._id,
                data: { teamId: teamToAdd._id },
            });
            setTeamToRemove(null);
            reduxDispatch(getSeason(season._id));
        } catch (e) {
            setResMessage({
                message:
                    (e.response && e.response.data) ||
                    "There was an error adding the team.",
            });
        }
    };

    const isLateRegSeason = () => {
        return season.name.includes("Late Registration");
    };

    const confirmInitTextComponent = () => {
        return (
            <SContainer hasFlex marg="0 0 20px" max-wid="50%">
                <SContainer marg="20px" itemsAlign="center">
                    <Text>Division Size:</Text>
                    <Input
                        placeholder="6"
                        value={divisionSize}
                        onChange={(e) => {
                            console.log(e.target.value);
                            setDivisionSize(e.target.value);
                        }}
                    />
                    <Text style={{ marginTop: "16px" }}>
                        {initialiseDivisionMessage}
                    </Text>
                </SContainer>
            </SContainer>
        );
    };

    const confirmRatingAdjustmentComponent = () => {
        const title = includeAllLeagues
            ? "Confirm you want to create rating adjustments for all closed seasons of all leagues."
            : "Confirm you want to create rating adjustments for the previous season.";
        const text = includeAllLeagues
            ? "Rating adjustments will be created for all closed seasons of all leagues for which no rating adjustments have been created yet."
            : "Rating adjustments will be created only for the previous season of this league, if they don't exist yet";

        return (
            <SContainer hasFlex marg="0 0 20px" max-wid="50%">
                <SContainer marg="20px" itemsAlign="center">
                    <Text>{title}</Text>
                    <Checkbox
                        checked={includeAllLeagues}
                        onChange={() =>
                            setIncludeAllLeagues(!includeAllLeagues)
                        }
                        label="Run for all leagues"
                    />
                    <Text style={{ marginTop: "16px" }}>
                        <small>{text}</small>
                    </Text>
                </SContainer>
            </SContainer>
        );
    };

    return (
        <>
            <GridColumn style={{ width: 300 }}>
                <TeamsSelect
                    onSelect={(team) => {
                        addTeam(team);
                    }}
                    useConfirm
                    confirmContent="This will add team to season"
                    confirmHeader="Are you sure?"
                    placeholder="Add new team"
                />
            </GridColumn>

            <Table sortable celled collapsing style={{ width: "100%" }}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={
                                sortColumn === "name" ? sortDirection : null
                            }
                            onClick={() =>
                                dispatch({
                                    type: "CHANGE_SORT",
                                    sortColumn: "name",
                                })
                            }
                        >
                            Team
                        </Table.HeaderCell>
                        {/* <Table.HeaderCell
          sorted={sortColumn === 'unsubscribe' ? sortDirection : null}
          onClick={() => dispatch({ type: 'CHANGE_SORT', sortColumn: 'unsubscribe' })}
        >Unsubscribed?</Table.HeaderCell> */}
                        {isLateRegSeason() && (
                            <Table.HeaderCell></Table.HeaderCell>
                        )}
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {data.map((team, index) => (
                        <Table.Row key={team._id}>
                            <Table.Cell>{index + 1}</Table.Cell>
                            <Table.Cell>
                                <Popup
                                    content={team.name}
                                    header={team.name}
                                    hoverable
                                    trigger={
                                        <Header as="h4" image>
                                            <Image
                                                src={team.avatar}
                                                rounded
                                                size="mini"
                                            />
                                            <Header.Content>
                                                {team.name}
                                                {/* <Header.Subheader>{player.email}</Header.Subheader> */}
                                            </Header.Content>
                                        </Header>
                                    }
                                />
                            </Table.Cell>
                            {/* <Table.Cell>{player.gender}</Table.Cell> */}
                            {/* <Table.Cell>{player.skillLevel}</Table.Cell> */}
                            {/* <Table.Cell>{player.leagueEntrySkillLevel}</Table.Cell> */}
                            {/* <Table.Cell>
            <Input
              style={{width:"5rem"}}
              // placeholder=''
              onChange={e => {
                playersLeagueSkills[player._id] = e.target.value;
                setPlayersLeagueSkills({ ...playersLeagueSkills });
              }}
              onBlur={e => overridePlayerSkill(player._id, e.target.value) }
              value={playersLeagueSkills[player._id] >= 0 ? playersLeagueSkills[player._id] : '' }
              // value={1}
            />
          </Table.Cell>           */}
                            {/* <Table.Cell>{Math.round(player.spinRating)}</Table.Cell>
          <Table.Cell>{player.devices && player.devices.length > 0 ? 'true' : 'false'}</Table.Cell>
          <LeagueUserPayment playerId={player._id} season={season}/>
          <Table.Cell>{player.notificationSettings.unsubscribe ? (player.notificationSettings.unsubscribe).toString() : 'false'}</Table.Cell> */}
                            {/* <Table.Cell>{player.lastOnline}</Table.Cell> */}
                            {/* <Table.Cell>{player.createdAt}</Table.Cell> */}
                            {/* <Table.Cell>
            <Input
              style={{width:"5rem"}}
              // placeholder=''
              onChange={e => updatePlayersData(index, e.target.value) }
              value={player.division}
              // value={1}
            />
          </Table.Cell> */}
                            <Table.Cell>
                                <Button
                                    content="Remove"
                                    size="tiny"
                                    negative
                                    onClick={() => setTeamToRemove(team)}
                                />
                            </Table.Cell>
                            {isLateRegSeason() && (
                                <Table.Cell>
                                    <Button
                                        onClick={() => moveToLive(team._id)}
                                    >
                                        Move to live (WIP)
                                    </Button>
                                </Table.Cell>
                            )}
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <GridRow>
                <Button
                    content="Update Division Plan"
                    onClick={() => setShowUpdateConfirm(true)}
                />
                <Button
                    content="Initialise Divisions"
                    onClick={() => {
                        setShowInitConfirm(true);
                        setShowInitConfirmOpts({
                            header: "Enter division size",
                            content: confirmInitTextComponent,
                            action: () => {},
                        });
                    }}
                />
                <Button
                    content="Create Rating Adjustments"
                    onClick={() => setShowRatingAdjustmentConfirm(true)}
                />

                {/* <Dropdown.Item content={(<Clickable onClick={() => this.setState({
                  showConfirm: true,
                  confirmHeader: 'Enter Title and Message',
                  confirmContent: () => this.pushTextComponent(),
                  confirmAction: () => this.sendPromoCommunication({ type: 'promo', title: pushTitle, message: pushMessage }),
                })}><Text size="13px">Send Promotional Push</Text></Clickable>)}/>     */}
            </GridRow>
            <Confirm
                open={showInitConfirm}
                header="Initialise Divisions?"
                // content='This will initialise divisions to the default recommendation.'
                content={confirmInitTextComponent}
                onCancel={() => {
                    setShowInitConfirm(false);
                    setLoading(false);
                }}
                onConfirm={initDivisions}
                confirmButton={
                    <Button loading={loading} disabled={loading}>
                        Ok
                    </Button>
                }
            />
            <Confirm
                open={showUpdateConfirm}
                header="Update Division Plan Data?"
                content="This will update the division plan with new players who have joined since the last time it was created/updated."
                onCancel={() => setShowUpdateConfirm(false)}
                onConfirm={() => initDivisions(false)}
                confirmButton={
                    <Button loading={loading} disabled={loading}>
                        Ok
                    </Button>
                }
            />
            <Confirm
                open={showRatingAdjustmentConfirm}
                header="Create Rating Adjustments?"
                content={confirmRatingAdjustmentComponent}
                onCancel={() => {
                    setShowRatingAdjustmentConfirm(false);
                    setLoading(false);
                }}
                onConfirm={async () => {
                    setLoading(true);
                    await createPastSeasonRatingAdjustment({
                        includeAllLeagues,
                    });
                    setLoading(false);
                    setShowRatingAdjustmentConfirm(false);
                }}
                confirmButton={
                    <Button loading={loading} disabled={loading}>
                        Ok
                    </Button>
                }
            />
            <Confirm
                open={!!teamToRemove}
                header={
                    teamToRemove
                        ? `Remove ${teamToRemove.name} from this season?`
                        : ""
                }
                content="Team will be removed from this season."
                onCancel={() => setTeamToRemove(null)}
                onConfirm={removeTeam}
            />
            {resMessage && (
                <Message
                    floating
                    positive={resMessage.message === "Success" ? true : false}
                    negative={resMessage.message === "Success" ? false : true}
                    header={resMessage.message}
                    content={
                        resMessage.checkMessages &&
                        resMessage.checkMessages.length > 0 ? (
                            <>
                                {resMessage.checkMessages.map(
                                    (checkMessage, index) => (
                                        <p key={index}>{checkMessage}</p>
                                    )
                                )}
                            </>
                        ) : (
                            <></>
                        )
                    }
                />
            )}
        </>
    );
};

export default SeasonPlayingTeamsTable;
