import { gql, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Dropdown } from "semantic-ui-react";
import geographicAreaByName from "../../graphql/queries/geographicAreaByName";

const LocationSelect = ({ onSelect, type, parent, current }) => {
  const [name, setName] = useState('');
  const [currentLocationId, setCurrentLocationId] = useState(null);

  const GET_LOCATION = gql`
  query GetLocation($id: MongoID!) {
    geographicAreaById(_id: $id) {
      _id
      name {
        en_GB
      }
    }
  }
`;  

  const { data: currentLocation } = useQuery(GET_LOCATION, {
    variables: { id: current },
    onCompleted(response) {  
      setCurrentLocationId(response.geographicAreaById._id);
    },    
  });
  
  const { loading, data, error, refetch } = useQuery(geographicAreaByName, {
    variables: { type, name, parent },
    // onCompleted(response) {
    // },
  });

  return (
    <Dropdown
      placeholder={type}
      search
      fluid
      selection
      clearable
      value={currentLocationId}
      onSearchChange={(e) => {
        setName(e.target.value);
        // refetch({ search: e.target.value})
      }}
      onChange={(e, data) => {       
        if (data.value) {
          const option = data.options.find(opt => opt.value === data.value);
          setCurrentLocationId(option.value);
          onSelect && onSelect(option);  
        } else {
          setCurrentLocationId(null);
          onSelect && onSelect(null);
        }
        
      }}
      options={data ? data.geographicAreaPagination.items.map(c => ({ key: c._id, value: c._id, text: c.name.en_GB, ...c })) : []}
    />    
  )
}

export default LocationSelect;