import React from 'react'
import ObjectID from 'bson-objectid'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import {
  getSeason,
  startSeason,
  createDivisions,
  calculateStandings,
  dragPlayer,
  addDivision,
  deleteDivision,
  editSeason,
  closeSeason,
  checkSeason,
} from 'store/actions/seasons'
import theme from 'lib/theme'
import { Header, Menu, Table, Image, Label, Card, Icon, Modal, Input, Form, Message, Button as SButton, TextArea, Dimmer, Loader, Dropdown, Confirm, Container, Grid, Tab } from 'semantic-ui-react'
import { withRouter, NavLink } from 'react-router-dom'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import DatePicker from 'react-datepicker'
import {
  Clickable,
  Button,
  Text,
  Avatar
} from 'components'
import { addDays, differenceInHours, format } from 'date-fns'
import Season from './Season'
import runGlicko from '../../store/actions/seasons/runGlicko'
import SeasonPlayersTable from '../../components/SeasonPlayersTable'
import startLateReg from '../../store/actions/seasons/startLateReg'
import { debounce } from 'debounce'
import sendPromotional from '../../store/actions/seasons/sendPromotional'
import sendCommunication from '../../store/actions/seasons/sendCommunication'
import CourtsSelect from '../../components/common/CourtsSelect'
import DivisionPlanTable from '../../components/DivisionPlanTable'
import SeasonDivisions from '../../components/SeasonDivisions'
import DivisionsPanes from '../../components/DivisionsPanes'

class Divisions extends React.Component{
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidUpdate(prevProps, prevState) {
    const { seasonId, season } = this.props;
        
    if (prevProps.seasonId !== seasonId || !season.users) {
      this.fetchSeason();
    }   
  }    
 
  componentWillMount() {
    this.fetchSeason();
  }

  fetchSeason = async () => {
    const { seasonId, dispatch } = this.props;
    await dispatch(getSeason(seasonId));    
  }

  reload() {
    const history = useHistory()
    history.go(0)
  }

  render() {
    const { season } = this.props
      
    if(!season || !season.divisions || !season.users) return <Loader active inline='centered' />

  //   const divisionPlan = (
  //       <Container>
  //         <Grid centered>
  //           <Grid.Row>
  //             <h1>Division Plan</h1>
  //           </Grid.Row>
  //           <Grid.Row>
  //             <h2>{season.league.name} - {season.name}</h2>
  //           </Grid.Row>
  //           <DivisionPlanTable season={season}/>
  //         </Grid>
  //       </Container>      
  //   )

  //   const divisions = (
  //     <Container>
  //       <Grid centered>
  //         <Grid.Row>
  //           <h1>Divisions</h1>
  //         </Grid.Row>
  //         <Grid.Row>
  //           <h2>{season.league.name} - {season.name}</h2>
  //         </Grid.Row>
  //         <SeasonDivisions 
  //           divisions={season.divisions} 
  //           players={season.users} 
  //           seasonId={season._id} 
  //           onMerge={this.fetchSeason}
  //         />
  //       </Grid>
  //     </Container>      
  // )    

  //   const panes = [
  //     { menuItem: 'Current Divisions', render: () => <Tab.Pane>{divisions}</Tab.Pane> },
  //     { menuItem: 'Division Plan', render: () => <Tab.Pane>{divisionPlan}</Tab.Pane> },
  //     // { menuItem: 'Tab 3', render: () => <Tab.Pane>Tab 3 Content</Tab.Pane> },
  //   ]
    
  //   const TabExampleBasic = () => <Tab panes={panes} />

  //   return (      
  //     <React.Fragment>
  //       <TabExampleBasic/>
  //     </React.Fragment>
  //   )
  
  return <DivisionsPanes season={season} refetchSeason={this.fetchSeason} />
  }
}

const mapStateToProps = (state, ownProps) => {
  const seasonId = ownProps.match.params.id

  return {
    seasonId,
    season: state.seasons.find(s => s._id === seasonId)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getSeason: payload => {
      dispatch(getSeason(payload))
    },
    dispatch,
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Divisions)
)
