import styled from 'styled-components'

const Text = styled.p`
  font-weight: ${props => props.weight ? props.weight : '400'};
  width: auto;
  height: ${props => props.ht ? props.ht : 'auto'};
  color: ${props => props.col ? props.col : 'black'};
  font-size: ${props => props.size ? props.size : '15px'};
  text-align: ${props => props.ta ? props.ta : 'left'};
  padding: ${props => props.marg ? props.marg : 0};  
  flex-shrink: ${props => props.shrinkFlex ? props.shrinkFlex : 0};
  text-decoration: ${props => props.underline ? 'underline' : 'none'};
  text-decoration-color: ${props => props.col ? props.col : 'black'};
`

export default Text
