import React, { useState } from "react";
import { Button } from "components";
import Api from "lib/api";
import { Image } from "semantic-ui-react";
import { useQuery, useMutation, gql } from "@apollo/client";
import USER from "../../graphql/queries/user";

const UserHeaderBox = (props) => {
  const [user, setUser] = useState(null);
  const { loading, data, error } = useQuery(USER, {
    // variables: { id: props.chatId },
    onCompleted(response) {
      if (response && response.user) {
        setUser(response.user);
      }
    },
  });
  if (error || !user) {
    return null;
  }

  //   return <span>{user.firstName}</span>

  return (
    <div style={{display: 'flex', alignItems: 'center'}}>
      <span>
        {user.firstName} {user.lastName}
      </span>
      <span style={{padding: 10}}><Image src={user.avatar} rounded size="mini" /></span>
    </div>
  );
};

export default UserHeaderBox;
