import React, { useState } from "react";
import _ from 'lodash'
import { Header, Table, Image, Input, Button, Confirm, Message, GridRow, Dropdown, Loader } from 'semantic-ui-react'
import { useDispatch } from "react-redux";

function reducer(state, action) {
  switch (action.type) {
    case 'CHANGE_SORT':
      if (state.sortColumn === action.sortColumn) {
        return {
          ...state,
          data: state.data.slice().reverse(),
          sortDirection:
            state.sortDirection === 'ascending' ? 'descending' : 'ascending',
        }
      }

      return {
        sortColumn: action.sortColumn,
        data: _.sortBy(state.data, action.sortColumn === 'newDivision' ? (e) => {
          const parts = e.newDivision.split(' ');
          return parseInt(parts[parts.length - 1]);
        }: [action.sortColumn]),
        // data: state.data,
        sortDirection: 'ascending',
      }
    case 'UPDATE_PLAYERS':
      return {
        ...state,
        data: action.data,
      }
    case 'UPDATE_PLAN':
      return {
        ...state,
        data: action.data,
      }      
    default:
      throw new Error()
  }
}  


const DivisionPlanView = ({ season, divisionPlan}) => {
  const [showSaveConfirm, setShowSaveConfirm] = useState(false);
  const [showInitConfirm, setShowInitConfirm] = useState(false);
  const [resMessage, setResMessage] = useState();
  const [tableModified, setTableModified] = useState(false);

  const initData = divisionPlan.map(row => {
    for (const division of season.divisions) {
      console.log('---division go on---', division)
      for (const team of division.teams) {
        console.log(team.teamId);
        console.log(row._id);
        console.log(team.teamId === row.teamId);
        if (team.teamId === row._id) {
          return { ...row, current: division.name}
        }
      }
    }
    return row;
  })  

  const [state, dispatch] = React.useReducer(reducer, {
    sortColumn: null,
    // data: buildPlayersTable(season),
    // data: response && response.divisionPlans[0].players.map(row => ({ ...row, ...row.player })),
    data: initData,
    sortDirection: null,
  }) ;

  const reduxDispatch = useDispatch();

  const { data, sortColumn, sortDirection } = state;

  console.log("data is ", data);

  if (!data) return (<GridRow centered><Loader active inline='centered' /></GridRow>);

  const getRowType = (player) => {
    if ((player.pastSeasonGamePercentage > 0.75 || player.pastSeasonGamePercentage < 0.25) && (player.current === player.newDivision) && !(player.status === "new") && !(player.status === "verified")) {
      return { error: true };
    }
  }

  const divSummary = data.reduce((prev, row) => { 
    const division = row.current || row.newDivision;
    if (prev[division]) {
      prev[division] = prev[division] + 1;
    } else {
      prev[division] = 1;
    }
    
    return prev;
  }, {});

  const divSummarySortedKeys = _.sortBy(Object.keys(divSummary), (key) => {
    const parts = key.split(' ');
    const multiplier = parts[0].includes('Entry') ? 1000 : 1;
    return parseInt(parts[parts.length - 1]) * multiplier;
  })

  console.log("Date is ", data);
  console.log("Season is ", season);
  console.log("Div Summary ", divSummary);

  return (
  <>
  <GridRow centered>
  <Table sortable celled collapsing basic>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell></Table.HeaderCell>        
        <Table.HeaderCell
          sorted={sortColumn === 'name' ? sortDirection : null}
          onClick={() => dispatch({ type: 'CHANGE_SORT', sortColumn: 'name' })}
        >Team</Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'skillLevel' ? sortDirection : null}
          onClick={() => dispatch({ type: 'CHANGE_SORT', sortColumn: 'skillLevel' })}        
        >Skill</Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'leagueEntrySkillLevel' ? sortDirection : null}
          onClick={() => dispatch({ type: 'CHANGE_SORT', sortColumn: 'leagueEntrySkillLevel' })}
        >League Skill</Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'spinRating' ? sortDirection : null}
          onClick={() => dispatch({ type: 'CHANGE_SORT', sortColumn: 'spinRating' })}
        >Rating</Table.HeaderCell>        
        <Table.HeaderCell
          sorted={sortColumn === 'status' ? sortDirection : null}
          onClick={() => dispatch({ type: 'CHANGE_SORT', sortColumn: 'status' })}
        >Status</Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'previousDivision' ? sortDirection : null}
          onClick={() => dispatch({ type: 'CHANGE_SORT', sortColumn: 'previousDivision' })}
        >Previous</Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'newDivision' ? sortDirection : null}
          onClick={() => dispatch({ type: 'CHANGE_SORT', sortColumn: 'newDivision' })}
        >New</Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'pastSeasonGamePercentage' ? sortDirection : null}
          onClick={() => dispatch({ type: 'CHANGE_SORT', sortColumn: 'pastSeasonGamePercentage' })}
        >Past % Won </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'current' ? sortDirection : null}
          onClick={() => dispatch({ type: 'CHANGE_SORT', sortColumn: 'current' })}
        >Current</Table.HeaderCell>              
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {data.map((player, index) => (
        <Table.Row key={player._id} {...getRowType(player)}>
          <Table.Cell>{index + 1}</Table.Cell>
          <Table.Cell>
            <Header as='h4' image>
              <Image src={player.avatar} rounded size='mini' />
              <Header.Content>
                {player.name}
              </Header.Content>
            </Header>
          </Table.Cell>
          <Table.Cell>{player.skillLevel || 0}</Table.Cell>
          <Table.Cell>{player.leagueEntrySkillLevel || 0}</Table.Cell>
          <Table.Cell>{Math.round(player.spinRating || 0)}</Table.Cell>
          <Table.Cell>{player.status}</Table.Cell>
          <Table.Cell>{player.previousDivision}</Table.Cell>
          <Table.Cell>{player.newDivision}</Table.Cell>
          <Table.Cell>{(player.pastSeasonGamePercentage * 1).toLocaleString(undefined,{style: 'percent', minimumFractionDigits: 1})}</Table.Cell>
          <Table.Cell>  
            {player.current}
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
  </GridRow>
  <GridRow centered>
    <h4>Summary</h4> 
  </GridRow>
  <GridRow centered>
    <Table celled collapsing basic>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell></Table.HeaderCell>        
          <Table.HeaderCell>Division</Table.HeaderCell>
          <Table.HeaderCell># Players</Table.HeaderCell>        
          <Table.HeaderCell>Min Skill</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {divSummarySortedKeys.map((key, index) => (
          <Table.Row key={key} >
            <Table.Cell>{index + 1}</Table.Cell>
            <Table.Cell>{key}</Table.Cell>
            <Table.Cell>{divSummary[key]}</Table.Cell>
            <Table.Cell>{season.divisions.find(div => div.name === key) ? season.divisions.find(div => div.name === key).minSkill : ''}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  </GridRow>
  </>
  );        
}

export default DivisionPlanView;