import Api from 'lib/api'

const getSeason = (payload) => {
  return dispatch => {
    Api.get(`/seasons/${payload}/?full=true`, payload, (err, res) => {
      if(err) return console.log('err', err)

      dispatch({
        type: 'SAVE_SEASON',
        payload: res
      })
    })
  }
}

export default getSeason
