import Api from 'lib/api'

const editSeason = (payload) => {
  return dispatch => {
    Api.post('/seasons/editSeason', payload, (err, res) => {
      if(err) return alert('there was an error saving')
    })
  }
}

export default editSeason
