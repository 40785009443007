import React, { useState } from "react";
import _ from 'lodash'
import { Header, Table, Image, Input, Button, Confirm, Message, GridRow, Dropdown, Loader } from 'semantic-ui-react'
import { useDispatch } from "react-redux";
import communications from "../graphql/queries/communications";
import { useQuery, useMutation } from "@apollo/client";
import { formatInTimeZone } from "date-fns-tz";
import { defaultTimezone } from "../lib/constants";
import UPDATE_COMMUNICATION from "../graphql/mutations/updateCommunication";
import ZonedDatePicker from "./ZonedDatePicker";


const SeasonCommunicationsTable = ({ season }) => {
  const [showSaveConfirm, setShowSaveConfirm] = useState(false);
  const [showInitConfirm, setShowInitConfirm] = useState(false);
  const [resMessage, setResMessage] = useState();

  const { loading, data, error, refetch } = useQuery(communications, {
    variables: { seasonId: season._id },
    // onCompleted: _data => {
    //   if (_data && _data.divisionPlans.length > 0) {
    //     dispatch(
    //       { type: 'UPDATE_PLAN', data:  _data.divisionPlans[0].players.map(row => ({ ...row, ...row.player, player: null }))}
    //     );
    //   }
    // },    
  });  

  // console.log("Response is ", response);
  console.log(error);
  console.log("Season is ", season);

  const reduxDispatch = useDispatch();
  const [updateCommunication] = useMutation(UPDATE_COMMUNICATION, {
    onCompleted: () => {
      refetch();
    }
  });
  const toggleCommunicationCancellation = async (communicationId, cancelled) => {
    const updatedCommunication = await updateCommunication({
      variables: {
        id: communicationId,
        record: {
          status: 'cancelled',
          cancelledAt: new Date(),
        }
      }
    })
    refetch();
    console.log('updated is', updatedCommunication);
  }
  // const { data, sortColumn, sortDirection } = state;

  // console.log("data is ", data);

  if (!data) return (<GridRow centered><Loader active inline='centered' /></GridRow>);

  return (
  <>
  <GridRow centered>
  <Table sortable celled collapsing basic>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell></Table.HeaderCell>        
        <Table.HeaderCell
          // sorted={sortColumn === 'name' ? sortDirection : null}
          // onClick={() => dispatch({ type: 'CHANGE_SORT', sortColumn: 'name' })}
        >Created</Table.HeaderCell>
        {/* <Table.HeaderCell
          sorted={sortColumn === 'gender' ? sortDirection : null}
          onClick={() => dispatch({ type: 'CHANGE_SORT', sortColumn: 'gender' })}
        >Gender</Table.HeaderCell> */}
        <Table.HeaderCell
          // sorted={sortColumn === 'skillLevel' ? sortDirection : null}
          // onClick={() => dispatch({ type: 'CHANGE_SORT', sortColumn: 'skillLevel' })}        
        >Scheduled for</Table.HeaderCell>
        <Table.HeaderCell
          // sorted={sortColumn === 'leagueEntrySkillLevel' ? sortDirection : null}
          // onClick={() => dispatch({ type: 'CHANGE_SORT', sortColumn: 'leagueEntrySkillLevel' })}
        >Sent</Table.HeaderCell>
        <Table.HeaderCell
          // sorted={sortColumn === 'spinRating' ? sortDirection : null}
          // onClick={() => dispatch({ type: 'CHANGE_SORT', sortColumn: 'spinRating' })}
        >Category</Table.HeaderCell>        
        <Table.HeaderCell
          // sorted={sortColumn === 'status' ? sortDirection : null}
          // onClick={() => dispatch({ type: 'CHANGE_SORT', sortColumn: 'status' })}
        >Type</Table.HeaderCell>
        {/* <Table.HeaderCell
          // sorted={sortColumn === 'previousDivision' ? sortDirection : null}
          // onClick={() => dispatch({ type: 'CHANGE_SORT', sortColumn: 'previousDivision' })}
        >Target</Table.HeaderCell> */}            
        <Table.HeaderCell>Status</Table.HeaderCell>
        <Table.HeaderCell>Error</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {data.communications.map((comm, index) => (
        <Table.Row key={comm._id}>
          <Table.Cell>{index + 1}</Table.Cell>
          <Table.Cell>{formatInTimeZone(comm.createdAt, defaultTimezone, 'MMM dd yyyy, H:mm' )}</Table.Cell>
          {/* <Table.Cell>{formatInTimeZone(comm.sendAt, defaultTimezone, 'MMM dd yyyy, H:mm' )}</Table.Cell> */}
          <Table.Cell>
            {
              <ZonedDatePicker
                selected={comm.sendAt}
                onChange={(date) => {
                  updateCommunication({
                    variables: {
                      id: comm._id,
                      record: {
                        sendAt: date,
                      },
                    },
                  });
                }}
                dateFormat="dd/MM/yyyy, HH:mm"
                timeFormat="HH:mm"
                showTimeSelect
              />
            }
          </Table.Cell>
          <Table.Cell>{comm.sentAt ? formatInTimeZone(comm.sentAt, defaultTimezone, 'MMM dd yyyy, H:mm' ) : ''}</Table.Cell>
          <Table.Cell>{comm.category}</Table.Cell>
          <Table.Cell>{comm.type}</Table.Cell>
          <Table.Cell>{comm.cancelled ? 'Cancelled' : comm.status}</Table.Cell>
          <Table.Cell>{comm.errorMsg}</Table.Cell>
          {/* <Table.Cell>{comm.target}</Table.Cell> */}
          {/* <Table.Cell></Table.Cell> */}
          {<Table.Cell>
             <Button
              content={comm.cancelled ? 'Reactivate' : 'Cancel'}
              size="tiny"
              negative={!comm.cancelled}
              onClick={() => {
                toggleCommunicationCancellation(comm._id, !comm.cancelled);
              }} />  
          </Table.Cell>}
          
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
  </GridRow>
  { resMessage && (
    <Message 
      floating 
      positive={resMessage === 'Success' ? true : false}
      negative={resMessage === 'Success' ? false : true}      
    >
      {/* <Message.Header>You are eligible for a reward</Message.Header> */}
      <p>
        {resMessage}
      </p>
    </Message>
  )}
  </>
  );        
}

export default SeasonCommunicationsTable;