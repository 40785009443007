import { combineReducers, createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'

// defaults to AsyncStorage
import storage from 'redux-persist/lib/storage'
import wsMiddleware from 'lib/ws'

// import reducers
import user from './reducers/user'
import leagues from './reducers/leagues'
import seasons from './reducers/seasons'

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: hardSet
}

const rootReducer = combineReducers({
  user,
  leagues,
  seasons,
})

const pReducer = persistReducer(rootPersistConfig, rootReducer)

export const store = createStore(
  pReducer,
  applyMiddleware(thunk, wsMiddleware)
)

export const persistor = persistStore(store)
