import { gql } from '@apollo/client';

const allRatingAdjustmentsForUser = gql`
  query AllRatingAdjustmentsForUser($playerId: MongoID) {
    ratingAdjustmentPagination(
      filter: { userId: $playerId },
      page: 1,
      perPage: 1000,
      sort: CUTOFF_ASC
    ) {
        items {
            _id
            value
            seasonId
            season {
              name
              league {
                name
              }
            }
            total
            appliedAt    
            cutoff        
        } 
    }
  }
`;

export default allRatingAdjustmentsForUser;
