import React, { useState } from "react";
import _ from 'lodash'
import { Header, Table, Image, Input, Button, Confirm, Message, GridRow, Dropdown, Loader } from 'semantic-ui-react'
import makeDivisions from "../store/actions/seasons/makeDivisions";
import updatePlayerLeagueSkill from "../store/actions/seasons/updatePlayerLeagueSkill";
import { getSeason } from 'store/actions/seasons';
import { useDispatch } from "react-redux";
import debounce from 'debounce'
import moveLatePlayerToLiveSeason from "../store/actions/seasons/moveLatePlayerToLiveSeason";
import divisionPlan from "../graphql/queries/divisionPlan";
import { useQuery } from "@apollo/client";

function reducer(state, action) {
  switch (action.type) {
    case 'CHANGE_SORT':
      if (state.sortColumn === action.sortColumn) {
        return {
          ...state,
          data: state.data.slice().reverse(),
          sortDirection:
            state.sortDirection === 'ascending' ? 'descending' : 'ascending',
        }
      }

      return {
        sortColumn: action.sortColumn,
        data: _.sortBy(state.data, action.sortColumn === 'newDivision' ? (e) => {
          const parts = e.newDivision.split(' ');
          return parseInt(parts[parts.length - 1]);
        }: [action.sortColumn]),
        // data: state.data,
        sortDirection: 'ascending',
      }
    case 'UPDATE_PLAYERS':
      return {
        ...state,
        data: action.data,
      }
    case 'UPDATE_PLAN':
      return {
        ...state,
        data: action.data,
      }      
    default:
      throw new Error()
  }
}  

function buildPlayersTable(season) {
  const playersTable = [];
  if (season.users) {      
    for (const player of season.users) {
      const divPlayer = { ...player, division: -1 };
      for (const [divIndex, division] of season.divisions.entries()) {
        for (const p of division.players) {
          if (p.playerId === player._id) {
            divPlayer.division = divIndex + 1;
            break;
          }
        }
      }
      playersTable.push(divPlayer);
    }
  }
  return playersTable;
} 

function buildPlayersLeagueSkills(season) {
  const leagueSkills = {};
  if (season.users) {      
    for (const player of season.users) {
      leagueSkills[player._id] = player.leagueEntrySkillLevel;
    }
  }  
  return leagueSkills;
}

const DivisionPlanTable = ({ season }) => {
  const [showSaveConfirm, setShowSaveConfirm] = useState(false);
  const [showInitConfirm, setShowInitConfirm] = useState(false);
  const [resMessage, setResMessage] = useState();
  const [tableModified, setTableModified] = useState(false);
  const [playersLeagueSkills, setPlayersLeagueSkills] = useState(buildPlayersLeagueSkills(season));
  const [overrides, setOverrides] = useState();

  const [state, dispatch] = React.useReducer(reducer, {
    sortColumn: null,
    // data: buildPlayersTable(season),
    // data: response && response.divisionPlans[0].players.map(row => ({ ...row, ...row.player })),
    data: null,
    sortDirection: null,
  }) ;

  const { loading, data: response, error, refetch } = useQuery(divisionPlan, {
    variables: { seasonId: season._id },
    onCompleted: _data => {
      if (_data && _data.divisionPlans.length > 0) {
        dispatch(
          { type: 'UPDATE_PLAN', data:  _data.divisionPlans[0].players.map(row => ({ ...row, ...row.player, player: null }))}
        );
      }
    },    
  });  

  console.log("Response is ", response);
  console.log(error);
  console.log("Season is ", season);

  const reduxDispatch = useDispatch();

  const { data, sortColumn, sortDirection } = state;

  console.log("data is ", data);

  if (!data) return (<GridRow centered><Loader active inline='centered' /></GridRow>);


  // const data = response.divisionPlans[0].players.map(row => ({ ...row, ...row.player }));


  // React.useEffect(() => {
  //   dispatch({ type: 'UPDATE_PLAYERS', data: buildPlayersTable(season)})
  // }, [season]);  

  // React.useEffect(() => {
  //   setPlayersLeagueSkills(buildPlayersLeagueSkills(season));
  // }, [season]);

  const updatePlayersData = (index, value) => {
    const pData = [...data];
    if (pData[index].division !== value) {
      pData[index] = { ...pData[index], division: value };
      dispatch({ type: 'UPDATE_PLAYERS', data: pData })
      setTableModified(true);
    }
  }

  const updateDivisionPlan = (index, value) => {
    console.log('in update plan', value);
    const player = data[index];
    if (player.override !== value) {      
      const updated = data.map(row => {
        return row._id === player._id ? { ...player, override: value } : row;
      })
      dispatch({ type: 'UPDATE_PLAN', data: updated})
      setTableModified(true);
    }  
  }

  const saveDivisionChanges = async () => {
    // const reqData = data.map(d => ({ _id: d._id, division: parseInt(d.division) })).filter(d => d.division > 0)
    // const finalData = data.map(row => ({...row, override: overrides[row._id]}));
    try {
      const res = await makeDivisions({ seasonId: season._id, data })
      setShowSaveConfirm(false);
      setResMessage({message: "Success", checkMessages: res.messages ? res.messages : []});
      // this.setState({ message: 'Success' });
    } catch (error) {
      setResMessage({
        message:"There was an error resetting the divisions:",
        checkMessages: error.response ? [error.response.data] : []
      });
      // this.setState({ message: 'Something went wrong' });      
    } 
    // setTimeout(() => {
    //   this.setState({ message: null })
    // }, 2000)    
  }

  const initDivisions = async () => {
    try {
      const res = await makeDivisions({ seasonId: season._id, data: null })
      setShowInitConfirm(false);
      setResMessage({message: "Success", checkMessages: res.messages ? res.messages : []});
      // this.setState({ message: 'Success' });
    } catch (error) {
      setResMessage({
        message:"There was an error initializing the divisions:",
        checkMessages: error.response ? [error.response.data] : []
      });
      // this.setState({ message: 'Something went wrong' });      
    } 
    // setTimeout(() => {
    //   this.setState({ message: null })
    // }, 2000)    
  }

  const overridePlayerSkill = async (playerId, skillOverride) => {
    // console.log("Will update ", {...playersLeagueSkills, playerId: skillOverride});
    try {
      await updatePlayerLeagueSkill({ playerId, skill: skillOverride });
      // setShowConfirm(false);
      // setResMessage("Success")
      // reduxDispatch(getSeason(season._id));
      // this.setState({ message: 'Success' });
    } catch {
      // setResMessage("There was an error resetting the divisions.")
      // this.setState({ message: 'Something went wrong' });      
    } 
    // setTimeout(() => {
    //   this.setState({ message: null })
    // }, 2000)    
  }  

  const moveToLive = async (playerId) => {
    // console.log("Will update ", {...playersLeagueSkills, playerId: skillOverride});
    try {
      await moveLatePlayerToLiveSeason({ seasonId: season._id, data: { playerId } });
      // setShowConfirm(false);
      // setResMessage("Success")
      reduxDispatch(getSeason(season._id));
      // this.setState({ message: 'Success' });
    } catch {
      setResMessage({message:"There was an error moving the player."});
      // this.setState({ message: 'Something went wrong' });      
    } 
    // setTimeout(() => {
    //   this.setState({ message: null })
    // }, 2000)    
  }    

  const isLateRegSeason = () => {
    return season.name.includes("Late Registration");
  }

  const getRowType = (player) => {
    if ((player.pastSeasonGamePercentage > 0.75 || player.pastSeasonGamePercentage < 0.25) && !player.override && !(player.status === "new") && !(player.status === "verified")) {
      return { error: true };
    }
  }

  // console.log(response);

  // const data = response.divisionPlans[0].players.map(row => ({ ...row, ...row.player }))
  const divSummary = data.reduce((prev, row) => { 
    const division = row.override || row.newDivision;
    if (prev[division]) {
      prev[division] = prev[division] + 1;
    } else {
      prev[division] = 1;
    }
    
    return prev;
  }, {});

  // update summary with overrides
  // overrides && Object.keys(overrides).forEach(key => {
  //   divSummary[overrides[key]]++;
  // });

  const divSummarySortedKeys = _.sortBy(Object.keys(divSummary), (key) => {
    const parts = key.split(' ');
    const multiplier = parts[0].includes('Entry') ? 1000 : 1;
    return parseInt(parts[parts.length - 1]) * multiplier;
  })

  console.log("Date is ", data);
  console.log("Season is ", season);
  console.log("Div Summary ", divSummary);
  console.log("Override ", overrides);

  return (
  <>
  <GridRow centered>
  <Table sortable celled collapsing basic>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell></Table.HeaderCell>        
        <Table.HeaderCell
          sorted={sortColumn === 'name' ? sortDirection : null}
          onClick={() => dispatch({ type: 'CHANGE_SORT', sortColumn: 'name' })}
        >Player</Table.HeaderCell>
        {/* <Table.HeaderCell
          sorted={sortColumn === 'gender' ? sortDirection : null}
          onClick={() => dispatch({ type: 'CHANGE_SORT', sortColumn: 'gender' })}
        >Gender</Table.HeaderCell> */}
        <Table.HeaderCell
          sorted={sortColumn === 'skillLevel' ? sortDirection : null}
          onClick={() => dispatch({ type: 'CHANGE_SORT', sortColumn: 'skillLevel' })}        
        >Skill</Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'leagueEntrySkillLevel' ? sortDirection : null}
          onClick={() => dispatch({ type: 'CHANGE_SORT', sortColumn: 'leagueEntrySkillLevel' })}
        >League Skill</Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'spinRating' ? sortDirection : null}
          onClick={() => dispatch({ type: 'CHANGE_SORT', sortColumn: 'spinRating' })}
        >Rating</Table.HeaderCell>        
        <Table.HeaderCell
          sorted={sortColumn === 'status' ? sortDirection : null}
          onClick={() => dispatch({ type: 'CHANGE_SORT', sortColumn: 'status' })}
        >Status</Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'previousDivision' ? sortDirection : null}
          onClick={() => dispatch({ type: 'CHANGE_SORT', sortColumn: 'previousDivision' })}
        >Previous</Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'newDivision' ? sortDirection : null}
          onClick={() => dispatch({ type: 'CHANGE_SORT', sortColumn: 'newDivision' })}
        >New</Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'pastSeasonGamePercentage' ? sortDirection : null}
          onClick={() => dispatch({ type: 'CHANGE_SORT', sortColumn: 'pastSeasonGamePercentage' })}
        >Past % Won </Table.HeaderCell>
        <Table.HeaderCell
          sorted={sortColumn === 'override' ? sortDirection : null}
          onClick={() => dispatch({ type: 'CHANGE_SORT', sortColumn: 'override' })}
        >Override</Table.HeaderCell>        
        {/* <Table.HeaderCell>Last Online</Table.HeaderCell> */}
        {/* <Table.HeaderCell>Created At</Table.HeaderCell> */}    
        {/* status
        previousDivision,
        newDivision,
        override,
        pastSeasonGamePercentage, uf            */}
        {/* <Table.HeaderCell
          sorted={sortColumn === 'division' ? sortDirection : null}
          onClick={() => dispatch({ type: 'CHANGE_SORT', sortColumn: 'division' })}
        >Division</Table.HeaderCell> */}
        { isLateRegSeason() && (
          <Table.HeaderCell
          ></Table.HeaderCell>
        )}
        
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {data.map((player, index) => (
        <Table.Row key={player._id} {...getRowType(player)}>
          <Table.Cell>{index + 1}</Table.Cell>
          <Table.Cell>
            <Header as='h4' image>
              <Image src={player.avatar} rounded size='mini' />
              <Header.Content>
                {player.name}
                {/* <Header.Subheader>{player.email}</Header.Subheader> */}
              </Header.Content>
            </Header>
          </Table.Cell>
          {/* <Table.Cell>{player.gender}</Table.Cell> */}
          <Table.Cell>{player.skillLevel}</Table.Cell>
          <Table.Cell>{player.leagueEntrySkillLevel}</Table.Cell>
          {/* <Table.Cell>
            <Input
              style={{width:"5rem"}}
              // placeholder=''
              onChange={e => {
                playersLeagueSkills[player._id] = e.target.value;
                setPlayersLeagueSkills({ ...playersLeagueSkills });
              }}
              onBlur={e => overridePlayerSkill(player._id, e.target.value) }
              value={playersLeagueSkills[player._id] >= 0 ? playersLeagueSkills[player._id] : '' }
              // value={1}
            />
          </Table.Cell>           */}
          <Table.Cell>{Math.round(player.spinRating)}</Table.Cell>
          <Table.Cell>{player.status}</Table.Cell>
          <Table.Cell>{player.previousDivision}</Table.Cell>
          <Table.Cell>{player.newDivision}</Table.Cell>
          <Table.Cell>{(player.pastSeasonGamePercentage * 1).toLocaleString(undefined,{style: 'percent', minimumFractionDigits: 1})}</Table.Cell>
          {/* <Table.Cell>{player.override}</Table.Cell>           */}
          <Table.Cell>
          <Dropdown
            placeholder='Select Division'
            selection
            // value={overrides? overrides[player._id] : null}
            value={player.override}
            onChange={(e, data) => {
              updateDivisionPlan(index, data.value);
              // const tempOverrides = { ...overrides };
              // tempOverrides[player._id] = data.value;
              // setOverrides(tempOverrides);
              // setTableModified(true);
            }}
            options={season.divisions.map(div => ({ key: div.name, value: div.name, text: div.name }))}
          />             
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
  </GridRow>
  <GridRow centered>
    <h4>Summary</h4> 
  </GridRow>
  <GridRow centered>
    <Table celled collapsing basic>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell></Table.HeaderCell>        
          <Table.HeaderCell>Division</Table.HeaderCell>
          <Table.HeaderCell># Players</Table.HeaderCell>        
          <Table.HeaderCell>Min Skill</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {divSummarySortedKeys.map((key, index) => (
          <Table.Row key={key} >
            <Table.Cell>{index + 1}</Table.Cell>
            <Table.Cell>{key}</Table.Cell>
            <Table.Cell>{divSummary[key]}</Table.Cell>
            <Table.Cell>{season.divisions.find(div => div.name === key) ? season.divisions.find(div => div.name === key).minSkill : ''}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  </GridRow>
  <GridRow>
  <Button
    disabled={!tableModified}
    content='Save Changes'  
    onClick={() => setShowSaveConfirm(true)} />  
  <Button
    content='Initialise Divisions'
    onClick={() => setShowInitConfirm(true)} />      
  </GridRow>
  <Confirm
    open={showInitConfirm}
    header='Initialise Divisions?'
    content='This will initialise divisions to the default recommendation.'
    onCancel={() => setShowInitConfirm(false)}
    onConfirm={initDivisions}
  />
  <Confirm
    open={showSaveConfirm}
    header='Save Division Changes?'
    content='This will set divisions to match your changes. Are you sure?'
    onCancel={() => setShowSaveConfirm(false)}
    onConfirm={saveDivisionChanges}
  />      
  { resMessage && (
    <Message 
      floating 
      positive={resMessage.message === 'Success' ? true : false}
      negative={resMessage.message === 'Success' ? false : true}  
      header={resMessage.message}
      content={resMessage.checkMessages && resMessage.checkMessages.length > 0 ? <>
      {resMessage.checkMessages.map(checkMessage => <p>{checkMessage}</p>)}
      </> :
      <></>}   
    />)}
  </>
  );        
}

export default DivisionPlanTable;