import { gql } from '@apollo/client';
import { COMMUNICATION_FRAGMENT } from '../fragments/communication';

const UPDATE_COMMUNICATION = gql`
  mutation UpdateCommunication($id: MongoID!, $record: UpdateByIdCommunicationInput!) {
    communicationUpdateById(
      _id: $id,
      record: $record
    ) {
      recordId
      record {
          ...Communication
      }
  }
}
${COMMUNICATION_FRAGMENT}
`;

export default UPDATE_COMMUNICATION;
