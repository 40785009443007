import Api from 'lib/api'

const startLateReg = async ({ seasonId, data }) => {
  //return dispatch => {
  const res = await Api.postAsync(`/seasons/${seasonId}/startLateReg`, data);
  return res.data;
  //}
}

export default startLateReg;
