import React, { useEffect, useState, useRef } from "react";
import {
    Button,
    Checkbox,
    Confirm,
    Dropdown,
    Grid,
    GridColumn,
    GridRow,
    Header,
    Image,
    Item,
    Popup,
    Table,
    Icon,
    Input,
    Modal,
} from "semantic-ui-react";
import { Container, Text, LoginButton } from "components";
import { useDispatch } from "react-redux";
import mergeDivisions from "../store/actions/seasons/mergeDivisions";
import movePlayer from "../store/actions/seasons/movePlayer";
import theme from "lib/theme";
import LeagueUserGamesPlayed from "./LeagueUserGamesPlayed";
import LeagueUserWarningsSent from "./LeagueUserWarningsSent";
import updateDivisionPlayer from "../store/actions/seasons/updateDivisionPlayer";
import LeagueUserSkillAssesment from "./LeagueUserSkillAssesment";
import insertDivision from "../store/actions/seasons/insertDivision";
import updatePlayerLeagueSkill from "../store/actions/seasons/updatePlayerLeagueSkill";
import LeagueUserSkillReadjustment from "./LeagueUserSkillReadjustments";
import { Link, useLocation } from "react-router-dom";
import { Container as SContainer } from "components";
import DivisionStatsTable from "./DivisionStatsTable";
import grantWalkoversBasedOnWithdrawnPlayer from "../store/actions/seasons/grantWalkoversBasedOnWithdrawnPlayer";

const admin_url = process.env.REACT_APP_ADMIN_URL || "http://localhost:3001";

const SeasonDivisions = ({
    sortedDivisions,
    players,
    playingTeams,
    seasonId,
    onMerge,
    divisionPlan,
    seasonStatus,
    refetch,
}) => {
    const buildPlayersLeagueSkills = (players) => {
        const leagueSkills = {};
        for (const player of players) {
            leagueSkills[player._id] = player.leagueEntrySkillLevel;
        }
        return leagueSkills;
    };
    const [divisionsToMerge, setDivisionsToMerge] = useState([]);
    const [movePlayerData, setMovePlayerData] = useState();
    const [updateOffsetData, setUpdateOffsetData] = useState();
    const [withdrawPlayerData, setWithdrawPlayerData] = useState();

    const [selectedStatus, setSelectedStatus] = useState("active");
    const [statusChangesOptions, setStatusChangesOptions] = useState({
        enable: false,
        confirm: false,
        notifyChanges: false,
    });
    const [statusChangePlayersData, setStatusChangePlayersData] = useState({});
    const [successStatusChangePlayers, setSuccessStatusChangePlayers] =
        useState([]);
    const [errorStatusChangePlayers, setErrorStatusChangePlayers] = useState(
        []
    );

    const statsTableContainerRef = useRef(null);

    const [showStats, setShowStats] = useState(false);
    const [grantWalkovers, setGrantWalkovers] = useState(false);
    const [grantWalkoversMessages, setGrantWalkoversMessages] = useState([]);
    const [divisionIndexToInsert, setDivisionIndexToInsert] = useState();
    const [playersLeagueSkills, setPlayersLeagueSkills] = useState(
        buildPlayersLeagueSkills(players)
    );
    const [sortBy, setSortBy] = useState();
    const [loading, setLoading] = useState(false);
    const [updatedRatings, setUpdatedRatings] = useState({});
    const [updatedOffsets, setUpdatedOffsets] = useState({});
    //const [showOffsetConfirm, setShowInitConfirm] = useState(false);
    //const [showOffsetConfirmOpts, setShowOffsetConfirmOpts] = useState(false);
    const unassignedPlayers = players.filter((player) => {
        let playerFound = false;
        for (const division of sortedDivisions) {
            for (const divisionPlayer of division.players) {
                if (divisionPlayer.playerId === player._id) {
                    playerFound = true;
                }
            }
        }
        return !playerFound;
    });
    const unassignedPlayingTeams = playingTeams.filter((playingTeam) => {
        console.log("--playing team---", playingTeam);
        let playingTeamFound = false;
        for (const division of sortedDivisions) {
            for (const divisionPlayingTeam of division.teams) {
                if (divisionPlayingTeam.teamId === playingTeam._id) {
                    playingTeamFound = true;
                    console.log("---asdfdsf", divisionPlayingTeam);
                    console.log("---asdfdsf", playingTeam);
                }
            }
        }
        return !playingTeamFound;
    });

    unassignedPlayers.sort((p1, p2) => p2.spinRating - p1.spinRating);

    const merge = async () => {
        try {
            await mergeDivisions({ seasonId, data: divisionsToMerge });
            onMerge();
        } catch {
            // setResMessage("There was an error resetting the divisions.")
            // this.setState({ message: 'Something went wrong' });
        }
    };

    const insertNewDivision = async () => {
        try {
            await insertDivision({
                seasonId,
                data: { index: divisionIndexToInsert },
            });
            onMerge();
        } catch {
            // setResMessage("There was an error resetting the divisions.")
            // this.setState({ message: 'Something went wrong' });
        }
    };

    const movePlayerToDivision = async () => {
        setLoading(true);
        try {
            console.log(movePlayerData);
            await movePlayer({ seasonId, data: movePlayerData });
            onMerge();
        } catch {
            // setResMessage("There was an error resetting the divisions.")
            // this.setState({ message: 'Something went wrong' });
        }
    };

    const withdrawPlayerFromDivision = async () => {
        setLoading(true);
        try {
            const { divisionId, data, notifyChanges } = withdrawPlayerData;

            const status = data.additionalData.status;
            const playerId = data.playerId;

            await updateDivisionPlayer({
                seasonId,
                divisionId,
                data: { ...data, notifyChanges },
            });
            let messages;
            if (status === "withdrawn" && grantWalkovers) {
                try {
                    const res = await grantWalkoversBasedOnWithdrawnPlayer({
                        seasonId,
                        divisionId,
                        data: { withdrawnPlayerId: playerId },
                    });
                    messages = res.messages;
                } catch (error) {
                    messages = [error];
                }
                setWithdrawPlayerData(null);
                setLoading(false);
                if (messages.length > 0) {
                    setGrantWalkoversMessages(messages);
                }
            } else {
                onMerge();
            }
        } catch {
            setWithdrawPlayerData(null);
            setLoading(false);
            // setResMessage("There was an error resetting the divisions.")
            // this.setState({ message: 'Something went wrong' });
        }
    };

    const changePlayersStatus = async () => {
        const playersIds = Object.keys(statusChangePlayersData);
        if (playersIds < 1) return;
        for (let playerId of playersIds) {
            const playerData = statusChangePlayersData[playerId];
            try {
                let { divisionId, data } = playerData;
                const notifyChanges = statusChangesOptions.notifyChanges;
                data = {
                    ...data,
                    additionalData: {
                        ...data.additionalData,
                        status: selectedStatus,
                    },
                };
                await updateDivisionPlayer({
                    seasonId,
                    divisionId,
                    data: { ...data, notifyChanges },
                });
                setSuccessStatusChangePlayers((oldArray) => [
                    ...oldArray,
                    playerId,
                ]);
            } catch (error) {
                console.log(
                    `Error while updating ${playerData.name} status to ${playerData.data.additionalData.status}:`,
                    error
                );
                setErrorStatusChangePlayers((oldArray) => [
                    ...oldArray,
                    playerId,
                ]);
            }
        }
        onMerge();
    };

    const overridePlayerRatingOffset = async (
        playerId,
        divisionId,
        seasonId,
        ratingOffset
    ) => {
        try {
            await updateDivisionPlayer({
                seasonId,
                divisionId,
                data: { playerId, additionalData: { ratingOffset } },
            });
            setUpdatedOffsets({ ...updatedOffsets, [playerId]: ratingOffset });
        } catch (error) {
            console.log(error);
        }
    };

    const overridePlayerSkill = async (
        player,
        division,
        seasonId,
        skillOverride
    ) => {
        // console.log("Will update ", {...playersLeagueSkills, playerId: skillOverride});
        const playerId = player._id;

        try {
            const response = await updatePlayerLeagueSkill({
                playerId,
                skill: skillOverride,
                division,
                seasonId,
            });

            const currentPlayerRating = updatedRatings[playerId]
                ? updatedRatings[playerId]
                : player.spinRating;

            if (
                response.spinRating &&
                response.spinRating !== currentPlayerRating
            ) {
                const newPlayerRating = response.spinRating;
                setUpdatedRatings({
                    ...updatedRatings,
                    [playerId]: newPlayerRating,
                });
            }
            // setShowConfirm(false);
            // setResMessage("Success")
            // reduxDispatch(getSeason(season._id));
            // this.setState({ message: 'Success' });
        } catch {
            // setResMessage("There was an error resetting the divisions.")
            // this.setState({ message: 'Something went wrong' });
        }
        // setTimeout(() => {
        //   this.setState({ message: null })
        // }, 2000)
    };

    const toggleNotify = () =>
        setMovePlayerData({
            ...movePlayerData,
            notifyChanges: !movePlayerData.notifyChanges,
        });

    const toggleGrantWalkovers = () => setGrantWalkovers(!grantWalkovers);

    const toggleNotifyWithdraw = () =>
        setWithdrawPlayerData({
            ...withdrawPlayerData,
            notifyChanges: !withdrawPlayerData.notifyChanges,
        });

    const toggleNotifyStatusChanges = () => {
        setStatusChangesOptions({
            ...statusChangesOptions,
            notifyChanges: !statusChangesOptions.notifyChanges,
        });
    };

    const renderLabel = (option) => ({
        color: option.color,
        content: option.text,
    });

    const playerPopUpContent = (playerData, planData) => (
        <>
            <span>{playerData.email}</span>
            <br />
            <span>
                <strong>Skill: </strong>
                {playerData.leagueEntrySkillLevel}
            </span>
            <br />
            <span>
                <strong>Rating: </strong>
                {Math.round(
                    updatedRatings[playerData._id]
                        ? updatedRatings[playerData._id]
                        : playerData.spinRating
                )}
            </span>
            <br />
            <span>
                <strong>Verified: </strong>
                {playerData.isLeagueVerified ? "true" : "false"}
            </span>
            <br />
            <span>
                <strong>Status: </strong>
                {!!planData && planData.status}
            </span>
            <br />
            <span>
                <strong>Past Season: </strong>
                {!!planData && planData.previousSeasonName}
            </span>
            <br />
            <span>
                <strong>Past Division: </strong>
                {!!planData && planData.previousDivision}
            </span>
            <br />
            <span>
                <strong>Estimated Division: </strong>
                {!!planData && planData.newDivision}
            </span>
            <br />
            <span>
                <strong>Past Games Won: </strong>
                {(
                    !!planData && planData.pastSeasonGamePercentage * 1
                ).toLocaleString(undefined, {
                    style: "percent",
                    minimumFractionDigits: 1,
                })}
            </span>
            <br />
            <span>
                <strong>Comp Pts: </strong>
                {!!planData && planData.ratingOffset}
            </span>
            <br />
            <span>
                <a
                    target="_blank"
                    href={`${admin_url}/#/Game?displayedFilters={"status":true,"player":true}&filter={"status":"Played","player":"${playerData._id}"}&order=DESC&page=1&perPage=50&sort=createdAt`}
                >
                    View Match History
                </a>
            </span>
            <br />
            <span>
                <Link to={`/user/${playerData._id}`} target="_blank">
                    View Rating History
                </Link>
            </span>
            <br />
            <LeagueUserSkillAssesment
                seasonId={seasonId}
                playerId={playerData._id}
            />
        </>
    );

    const ConfirmRatingOffsetOverride = () => {
        return (
            <SContainer hasFlex marg="0 0 20px" max-wid="50%">
                <SContainer marg="20px" itemsAlign="center">
                    <Text>Offset:</Text>
                    <Input
                        type="number"
                        placeholder="0"
                        defaultValue={updateOffsetData.newOffsetValue}
                        onBlur={(e) => {
                            const newOffsetValue = e.target.value
                                ? parseInt(e.target.value)
                                : 0;
                            setUpdateOffsetData({
                                ...updateOffsetData,
                                newOffsetValue,
                            });
                        }}
                    />
                </SContainer>
            </SContainer>
        );
    };

    const getRowType = (row) => {
        if (row.status === "withdrawn") {
            return { error: true };
        } else if (row.status === "snoozed") {
            return { warning: true };
        } else if (row.score.played < 1) {
            return { warning: true };
        } else if (row.score.played > 0) {
            return { positive: true };
        }
    };

    const warnings = {};

    let divisions = [...sortedDivisions];

    let tmpPlayers = divisions[0].players;

    console.log(tmpPlayers);

    if (sortBy !== "default") {
        // if (sortBy) {
        divisions.forEach((division) => {
            division.players = [...division.players].sort((p1, p2) => {
                const p1Data = players.find((p) => p._id === p1.playerId);
                const p2Data = players.find((p) => p._id === p2.playerId);
                if (sortBy === "ratingOffset") {
                    const p1RatingOffset = updatedOffsets[p1.playerId]
                        ? updatedOffsets[p1.playerId]
                        : p1.ratingOffset;
                    const p1SpinRating = updatedRatings[p1.playerId]
                        ? updatedRatings[p1.playerId]
                        : p1Data.spinRating;

                    const p2RatingOffset = updatedOffsets[p2.playerId]
                        ? updatedOffsets[p2.playerId]
                        : p2.ratingOffset;
                    const p2SpinRating = updatedRatings[p2.playerId]
                        ? updatedRatings[p2.playerId]
                        : p2Data.spinRating;

                    return (
                        p2SpinRating +
                        p2RatingOffset -
                        (p1SpinRating + p1RatingOffset)
                    );
                } else if (sortBy === "rating") {
                    return p2Data.spinRating - p1Data.spinRating;
                }
            });
        });
    }

    divisions.forEach((division) => {
        console.log("-division---", division);
        let prevDivCounts = {};
        division.teams.forEach((row) => {
            const plan = divisionPlan.find((team) => team._id === row.teamId);
            if (plan && plan.previousDivision) {
                prevDivCounts[plan.previousDivision] = prevDivCounts[
                    plan.previousDivision
                ]
                    ? prevDivCounts[plan.previousDivision] + 1
                    : 1;
            }
        });
        const counts = Object.values(prevDivCounts).filter((v) => v > 3);
        if (counts.length > 0) {
            warnings[division.name] = true;
        }
    });

    console.log(warnings);

    const location = useLocation();

    useEffect(() => {
        const hash = location.hash;
        const targetPlayer = document.getElementById(hash.substr(1));
        if (targetPlayer) {
            targetPlayer.scrollIntoView({ behavior: "smooth" });
        }
    }, [location.hash]);

    return (
        <>
            <Grid>
                <GridRow>
                    <GridColumn
                        style={{ display: "flex", justifyContent: "start" }}
                    >
                        <Dropdown
                            style={{ marginRight: "10px" }}
                            placeholder="Sort By"
                            selection
                            // value={overrides? overrides[player._id] : null}
                            value={sortBy}
                            onChange={(e, data) => {
                                setSortBy(data.value);
                            }}
                            options={[
                                {
                                    key: "Rating + Offset",
                                    value: "ratingOffset",
                                    text: "Rating + Offset",
                                },
                                {
                                    key: "Spin Rating",
                                    value: "rating",
                                    text: "Spin Rating",
                                },
                                {
                                    key: "default",
                                    value: "default",
                                    text: "Default",
                                },
                            ]}
                        />
                        <Button
                            content="Show Stats"
                            onClick={() => setShowStats(true)}
                        />
                        {statusChangesOptions.enable && (
                            <Button
                                content="Change status"
                                onClick={() =>
                                    setStatusChangesOptions({
                                        ...statusChangesOptions,
                                        confirm: true,
                                    })
                                }
                            />
                        )}
                    </GridColumn>
                </GridRow>
                <GridRow>
                    <Grid.Column>
                        {divisions.map((division, index) => (
                            <Grid key={division._id}>
                                <GridRow>
                                    <Grid.Column>
                                        <Header as="h3" dividing>
                                            {division.name}
                                        </Header>
                                    </Grid.Column>
                                </GridRow>
                                <GridRow columns={2}>
                                    <Grid.Column width={16} textAlign="left">
                                        {index > 0 && (
                                            <Button
                                                content={`Merge up`}
                                                onClick={() => {
                                                    const firstDivision =
                                                        division;
                                                    const secondDivision =
                                                        divisions[index - 1];
                                                    if (
                                                        firstDivision.isEntry &&
                                                        !secondDivision.isEntry
                                                    ) {
                                                        setDivisionsToMerge([
                                                            secondDivision.name,
                                                            firstDivision.name,
                                                        ]);
                                                        return;
                                                    }
                                                    setDivisionsToMerge([
                                                        firstDivision.name,
                                                        secondDivision.name,
                                                    ]);
                                                }}
                                            />
                                        )}
                                        {!division.isEntry && (
                                            <Button
                                                content={`Create Division Above`}
                                                onClick={() =>
                                                    setDivisionIndexToInsert(
                                                        index
                                                    )
                                                }
                                            />
                                        )}
                                    </Grid.Column>
                                    <Grid.Column width={16}>
                                        <Table celled singleLine>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell></Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                        Played
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                        Warnings
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                        Teams
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                        Division
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                        Status
                                                    </Table.HeaderCell>
                                                    
                                                    {/* <Table.HeaderCell>Total</Table.HeaderCell> */}
                                                    <Table.HeaderCell></Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {division.teams.map((team) => {
                                                    const playingTeamData =
                                                        playingTeams.find(
                                                            (p) =>
                                                                p._id ===
                                                                team.teamId
                                                        );
                                                    const planData =
                                                        divisionPlan.find(
                                                            (row) =>
                                                                row._id ===
                                                                team.teamId
                                                        );
                                                    return (
                                                        <Table.Row
                                                            id={
                                                                playingTeamData._id
                                                            }
                                                            key={
                                                                playingTeamData._id
                                                            }
                                                            {...getRowType(
                                                                team
                                                            )}
                                                        >
                                                            <Table.Cell>
                                                                <Checkbox
                                                                    checked={
                                                                        playingTeamData._id in
                                                                        statusChangePlayersData
                                                                    }
                                                                    onChange={() => {
                                                                        const playerId =
                                                                            playingTeamData._id;
                                                                        const alreadyChecked =
                                                                            playerId in
                                                                            statusChangePlayersData;

                                                                        if (
                                                                            alreadyChecked
                                                                        ) {
                                                                            delete statusChangePlayersData[
                                                                                playerId
                                                                            ];
                                                                            setStatusChangePlayersData(
                                                                                {
                                                                                    ...statusChangePlayersData,
                                                                                }
                                                                            );
                                                                        } else {
                                                                            setStatusChangePlayersData(
                                                                                {
                                                                                    ...statusChangePlayersData,
                                                                                    [playerId]:
                                                                                        {
                                                                                            name: playingTeamData.name,
                                                                                            divisionId:
                                                                                                division._id,
                                                                                            divisionName:
                                                                                                division.name,
                                                                                            data: {
                                                                                                playerId:
                                                                                                    playerId,
                                                                                            },
                                                                                        },
                                                                                }
                                                                            );
                                                                        }

                                                                        if (
                                                                            statusChangesOptions.enable &&
                                                                            Object.keys(
                                                                                statusChangePlayersData
                                                                            )
                                                                                .length ===
                                                                                0
                                                                        ) {
                                                                            setStatusChangesOptions(
                                                                                {
                                                                                    ...statusChangesOptions,
                                                                                    enable: false,
                                                                                }
                                                                            );
                                                                        } else if (
                                                                            !statusChangesOptions.enable
                                                                        ) {
                                                                            setStatusChangesOptions(
                                                                                {
                                                                                    ...statusChangesOptions,
                                                                                    enable: true,
                                                                                }
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                {
                                                                    team.score
                                                                        .played
                                                                }
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                <LeagueUserWarningsSent
                                                                    seasonId={
                                                                        seasonId
                                                                    }
                                                                    playerId={
                                                                        team.teamId
                                                                    }
                                                                />
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                <Popup
                                                                    hoverable
                                                                    header={
                                                                        playingTeamData.name
                                                                    }
                                                                    content={() =>
                                                                        playerPopUpContent(
                                                                            playingTeamData,
                                                                            planData
                                                                        )
                                                                    }
                                                                    trigger={
                                                                        <Container
                                                                            marg="10px 0 0"
                                                                            itemsAlign="flex-start"
                                                                        >
                                                                            <Header
                                                                                as="h4"
                                                                                image
                                                                            >
                                                                                <Image
                                                                                    src={
                                                                                        playingTeamData.avatar
                                                                                    }
                                                                                    rounded
                                                                                    size="mini"
                                                                                />
                                                                                <Header.Content>
                                                                                    {
                                                                                        playingTeamData.name
                                                                                    }
                                                                                    {team.badge
                                                                                        ? ` - ${
                                                                                              team.badge ||
                                                                                              ""
                                                                                          }`
                                                                                        : ""}
                                                                                </Header.Content>
                                                                            </Header>
                                                                        </Container>
                                                                    }
                                                                />
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                <Dropdown
                                                                    text="Move to..."
                                                                    // options={options}
                                                                    options={divisions.map(
                                                                        (
                                                                            div
                                                                        ) => ({
                                                                            key: div.name,
                                                                            value: div.name,
                                                                            text: div.name,
                                                                        })
                                                                    )}
                                                                    onChange={(
                                                                        e,
                                                                        data
                                                                    ) => {
                                                                        setMovePlayerData(
                                                                            {
                                                                                playerId:
                                                                                    team.teamId,
                                                                                name: playingTeamData.name,
                                                                                newDivision:
                                                                                    data.value,
                                                                                oldDivision:
                                                                                    division.name,
                                                                            }
                                                                        );
                                                                    }}
                                                                />
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                <Dropdown
                                                                    defaultValue={
                                                                        [
                                                                            "withdrawn",
                                                                            "snoozed",
                                                                        ].includes(
                                                                            team.status
                                                                        )
                                                                            ? team.status
                                                                            : "active"
                                                                    }
                                                                    options={[
                                                                        {
                                                                            key: "active",
                                                                            value: "active",
                                                                            text: "Active",
                                                                            color: "green",
                                                                        },
                                                                        {
                                                                            key: "withdrawn",
                                                                            value: "withdrawn",
                                                                            text: "Withdrawn",
                                                                            color: "yellow",
                                                                        },
                                                                        {
                                                                            key: "snoozed",
                                                                            value: "snoozed",
                                                                            text: "Snoozed",
                                                                            color: "grey",
                                                                        },
                                                                    ]}
                                                                    onChange={(
                                                                        e,
                                                                        data
                                                                    ) => {
                                                                        setWithdrawPlayerData(
                                                                            {
                                                                                name: playingTeamData.name,
                                                                                divisionId:
                                                                                    division._id,
                                                                                divisionName:
                                                                                    division.name,
                                                                                data: {
                                                                                    playerId:
                                                                                        playingTeamData._id,
                                                                                    additionalData:
                                                                                        {
                                                                                            status: data.value,
                                                                                        },
                                                                                },
                                                                            }
                                                                        );
                                                                    }}
                                                                />
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                <Button
                                                                    content="Remove"
                                                                    size="tiny"
                                                                    negative
                                                                    onClick={() =>
                                                                        setMovePlayerData(
                                                                            {
                                                                                playerId:
                                                                                    playingTeamData._id,
                                                                                name: playingTeamData.name,
                                                                                newDivision:
                                                                                    null,
                                                                                oldDivision:
                                                                                    division.name,
                                                                            }
                                                                        )
                                                                    }
                                                                />
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    );
                                                })}
                                            </Table.Body>
                                        </Table>
                                    </Grid.Column>
                                </GridRow>
                            </Grid>
                        ))}
                        <Confirm
                            open={!!updateOffsetData}
                            header={
                                updateOffsetData
                                    ? `Are you sure you want to overwrite ${updateOffsetData.playerName}'s offset?`
                                    : ""
                            }
                            content={<ConfirmRatingOffsetOverride />}
                            onCancel={() => {
                                setUpdateOffsetData(null);
                            }}
                            onConfirm={async () => {
                                setLoading(true);
                                await overridePlayerRatingOffset(
                                    updateOffsetData.playerId,
                                    updateOffsetData.divisionId,
                                    seasonId,
                                    updateOffsetData.newOffsetValue
                                );
                                setUpdateOffsetData(null);
                                setLoading(false);
                            }}
                            confirmButton={
                                <Button loading={loading} disabled={loading}>
                                    Ok
                                </Button>
                            }
                        />
                        <Confirm
                            open={divisionsToMerge.length > 0}
                            header="Confirm merge?"
                            content={`Please confirm you want to merge ${divisionsToMerge[0]} and ${divisionsToMerge[1]}.`}
                            onCancel={() => setDivisionsToMerge([])}
                            onConfirm={merge}
                        />
                        <Confirm
                            open={divisionIndexToInsert >= 0}
                            header="Confirm insert?"
                            content={`Please confirm you want to insert a new division.`}
                            onCancel={() => setDivisionIndexToInsert(null)}
                            onConfirm={insertNewDivision}
                        />
                        <Confirm
                            open={!!movePlayerData}
                            header="Confirm move?"
                            content={() => {
                                const text = movePlayerData
                                    ? movePlayerData.newDivision
                                        ? `Please confirm you want to move ${movePlayerData.name} from ${movePlayerData.oldDivision} to ${movePlayerData.newDivision}.`
                                        : `Please confirm you want to remove ${movePlayerData.name} from ${movePlayerData.oldDivision}`
                                    : "";
                                return (
                                    <Container marg="10px">
                                        <span>{text}</span>
                                        <br />
                                        {seasonStatus === "live" && (
                                            <Checkbox
                                                onChange={toggleNotify}
                                                label="Notify players of changes"
                                            />
                                        )}
                                    </Container>
                                );
                            }}
                            onCancel={() => setMovePlayerData(null)}
                            onConfirm={movePlayerToDivision}
                            onClose={() => {
                                if (loading) {
                                    setLoading(false);
                                }
                            }}
                            confirmButton={
                                <Button loading={loading} disabled={loading}>
                                    Ok
                                </Button>
                            }
                        />
                        <Confirm
                            open={!!withdrawPlayerData}
                            header="Confirm Status Change?"
                            content={() => {
                                const status = withdrawPlayerData
                                    ? withdrawPlayerData.data.additionalData
                                          .status
                                    : "";
                                const text = withdrawPlayerData
                                    ? `Please confirm you want to change status to '${status}' for player ${withdrawPlayerData.name} from ${withdrawPlayerData.divisionName}`
                                    : "";
                                return (
                                    <Container marg="10px">
                                        <span>{text}</span>
                                        <br />
                                        {status === "withdrawn" && (
                                            <Checkbox
                                                label="Grant walkovers?"
                                                onChange={toggleGrantWalkovers}
                                            />
                                        )}
                                        {seasonStatus === "live" && (
                                            <Checkbox
                                                onChange={toggleNotifyWithdraw}
                                                label="Notify players of changes"
                                            />
                                        )}
                                        {grantWalkovers && (
                                            <div>
                                                <br />
                                                <small>
                                                    This will also grant
                                                    walkovers for all players
                                                    who haven't yet played
                                                    against the individual being
                                                    changed to 'Withdrawn'.
                                                </small>
                                            </div>
                                        )}
                                    </Container>
                                );
                            }}
                            onCancel={() => setWithdrawPlayerData(null)}
                            onConfirm={withdrawPlayerFromDivision}
                            confirmButton={
                                <Button loading={loading} disabled={loading}>
                                    Ok
                                </Button>
                            }
                        />
                        <Confirm
                            open={
                                Object.keys(statusChangePlayersData).length >
                                    0 && statusChangesOptions.confirm
                            }
                            header="Confirm the following Status Changes?"
                            content={() => (
                                <Container marg="10px">
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <span style={{ marginRight: "10px" }}>
                                            Please confirm you want to change
                                            the players status to:
                                        </span>
                                        <Dropdown
                                            placeholder="Status"
                                            selection
                                            defaultValue={selectedStatus}
                                            options={[
                                                {
                                                    key: "active",
                                                    value: "active",
                                                    text: "Active",
                                                    color: "green",
                                                },
                                                {
                                                    key: "withdrawn",
                                                    value: "withdrawn",
                                                    text: "Withdrawn",
                                                    color: "yellow",
                                                },
                                                {
                                                    key: "snoozed",
                                                    value: "snoozed",
                                                    text: "Snoozed",
                                                    color: "grey",
                                                },
                                            ]}
                                            onChange={(e, data) => {
                                                setSelectedStatus(data.value);
                                            }}
                                        />
                                    </div>
                                    <ul>
                                        {statusChangePlayersData &&
                                            Object.values(
                                                statusChangePlayersData
                                            ).map((player) => {
                                                const text = player
                                                    ? `Change status to '${selectedStatus}' for player ${player.name} from ${player.divisionName}`
                                                    : "";
                                                const playerId = player
                                                    ? player.data.playerId
                                                    : null;
                                                const color =
                                                    successStatusChangePlayers.includes(
                                                        playerId
                                                    )
                                                        ? "green"
                                                        : errorStatusChangePlayers.includes(
                                                              playerId
                                                          )
                                                        ? "red"
                                                        : "black";
                                                return (
                                                    <li
                                                        style={{
                                                            color,
                                                            listStyleType:
                                                                "none",
                                                        }}
                                                        key={playerId}
                                                    >
                                                        {text}
                                                    </li>
                                                );
                                            })}
                                    </ul>
                                    {seasonStatus === "live" && (
                                        <Checkbox
                                            onChange={toggleNotifyStatusChanges}
                                            label="Notify players of changes"
                                        />
                                    )}
                                </Container>
                            )}
                            onConfirm={async () => {
                                setLoading(true);
                                await changePlayersStatus();
                                setSuccessStatusChangePlayers([]);
                                setErrorStatusChangePlayers([]);
                                setStatusChangePlayersData({});
                                setStatusChangesOptions({
                                    ...statusChangesOptions,
                                    confirm: false,
                                    enable: false,
                                });
                            }}
                            onCancel={() => {
                                setStatusChangePlayersData({});
                                setStatusChangesOptions({
                                    ...statusChangesOptions,
                                    confirm: false,
                                    enable: false,
                                });
                            }}
                            onClose={() => {
                                if (loading) {
                                    setLoading(false);
                                }
                            }}
                            confirmButton={
                                <Button loading={loading} disabled={loading}>
                                    Ok
                                </Button>
                            }
                        />
                        <Modal
                            open={grantWalkoversMessages.length > 0}
                            header="Grant of walkovers"
                            content={
                                <Container style={{ margin: "30px" }}>
                                    <ul>
                                        {grantWalkoversMessages &&
                                            grantWalkoversMessages.map(
                                                (message) => (
                                                    <li
                                                        style={{
                                                            listStyleType:
                                                                "none",
                                                        }}
                                                    >
                                                        {message}
                                                    </li>
                                                )
                                            )}
                                    </ul>
                                </Container>
                            }
                            onClose={() => {
                                setGrantWalkoversMessages([]);
                                onMerge();
                            }}
                        />
                        <Modal
                            open={showStats}
                            onClose={() => setShowStats(false)}
                            header="Division Stats"
                            size="large"
                            content={
                                <Container style={{ margin: "30px" }}>
                                    {divisions.map((division) => (
                                        <DivisionStatsTable
                                            key={division._id}
                                            division={division}
                                            players={players}
                                            seasonId={seasonId}
                                        />
                                    ))}
                                </Container>
                            }
                        />
                    </Grid.Column>
                </GridRow>
                <GridRow>
                    <GridColumn>
                        <Header as="h3" dividing>
                            Unassigned Teams
                        </Header>
                        <Table celled singleLine>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Team</Table.HeaderCell>

                                    <Table.HeaderCell></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {console.log(
                                    "unassigned--",
                                    unassignedPlayingTeams
                                )}
                                {unassignedPlayingTeams.map((teamData) => {
                                    const planData = divisionPlan.find(
                                        (row) => row._id === teamData._id
                                    );
                                    return (
                                        <Table.Row
                                            id={teamData._id}
                                            key={teamData._id}
                                        >
                                            <Table.Cell>
                                                <Popup
                                                    hoverable
                                                    header={teamData.name}
                                                    content={() =>
                                                        playerPopUpContent(
                                                            teamData,
                                                            planData
                                                        )
                                                    }
                                                    trigger={
                                                        <Container
                                                            marg="10px 0 0"
                                                            itemsAlign="flex-start"
                                                        >
                                                            <Header
                                                                as="h4"
                                                                image
                                                            >
                                                                <Image
                                                                    src={
                                                                        teamData.avatar
                                                                    }
                                                                    rounded
                                                                    size="mini"
                                                                />
                                                                <Header.Content>
                                                                    {
                                                                        teamData.name
                                                                    }
                                                                </Header.Content>
                                                            </Header>
                                                        </Container>
                                                    }
                                                />
                                            </Table.Cell>

                                            <Table.Cell>
                                                <Dropdown
                                                    text="Move to..."
                                                    options={divisions.map(
                                                        (div) => ({
                                                            key: div.name,
                                                            value: div.name,
                                                            text: div.name,
                                                        })
                                                    )}
                                                    // onChange={(e, data) => {
                                                    //     setMovePlayerData({
                                                    //         playerId:
                                                    //             playerData._id,
                                                    //         name: playerData.name,
                                                    //         newDivision:
                                                    //             data.value,
                                                    //         oldDivision: null,
                                                    //     });
                                                    // }}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                    </GridColumn>
                </GridRow>
            </Grid>
        </>
    );
};

export default SeasonDivisions;
