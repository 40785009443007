import React from 'react'

import { Container, Input, LoginButton } from 'components'

class Login extends React.Component {
  state = {
    email: '',
    password: '',
  }

  render() {
    const { email, password } = this.state
    return (
      <Container contentJustify='center'>
        <Container
          miniContent
          centerContent
          selfAlign='center'
          contentJustify='center'
          noFlex
        >
          <Input
            value={email}
            placeholder='stanlee@marvel.com'
            autoFocus
            onChangeText={(email) => this.setState({ email })}
          />
          <Input
            value={password}
            placeholder='************'
            onChangeText={(password) => this.setState({ password })}
          />

          <LoginButton email={email} password={password}/>
        </Container>
      </Container>
    )
  }
}

export default Login
