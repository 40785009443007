import React, { useState } from "react";
import { GridRow, Loader, Grid, Container, Tab } from 'semantic-ui-react'
import divisionPlan from "../graphql/queries/divisionPlan";
import { useQuery } from "@apollo/client";
import SeasonDivisions from "./SeasonDivisions";
import DivisionPlanView from "./DivisionPlanView";


const DivisionsPanes = ({ season, refetchSeason }) => {
  const { loading, data, error, refetch } = useQuery(divisionPlan, {
    variables: { seasonId: season._id },
    fetchPolicy: 'cache-and-network',
  });
  console.log('---season----', season);

  if (!data || loading) return (<GridRow centered><Loader active inline='centered' /></GridRow>);

  const divisionPlanData = data.divisionPlans.length > 0 ? data.divisionPlans[0].teams.map(row => ({ ...row, ...row.team, team: null })) : [];
  const messages = data.divisionPlans.length > 0 && data.divisionPlans[0].messages ? data.divisionPlans[0].messages : []; 
  const divisionPlanComponent = (
    <Container>
      <Grid centered>
        <Grid.Row>
          <h1>Division Plan</h1>
        </Grid.Row>
        <Grid.Row>
          <h2>{season.league.name} - {season.name}</h2>
        </Grid.Row>
        {messages.length > 0 && <>
        <Grid.Row>
          <h4>Messages:</h4>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column centered textAlign="center">
            {messages.map((message,index) =>
              <><span key={index}>{message}</span><br></br></>)}
          </Grid.Column>
        </Grid.Row>
        </>}
        <DivisionPlanView season={season} divisionPlan={divisionPlanData}/>
      </Grid>
    </Container>      
)

const divisionsComponent = (
  <Container>
    <Grid centered>
      <Grid.Row>
        <h1>Divisions</h1>
      </Grid.Row>
      <Grid.Row>
        <h2>{season.league.name} - {season.name}</h2>
      </Grid.Row>
      <SeasonDivisions 
        sortedDivisions={season.divisions} 
        divisionPlan={divisionPlanData}
        players={season.users}
        playingTeams={season.teamIds}
        seasonId={season._id} 
        onMerge={refetchSeason}
        seasonStatus={season.status}
        refetch={refetch}
      />
    </Grid>
  </Container>      
)    

const panes = [
  { menuItem: 'Current Divisions', render: () => <Tab.Pane>{divisionsComponent}</Tab.Pane> },
  { menuItem: 'Division Plan', render: () => <Tab.Pane>{divisionPlanComponent}</Tab.Pane> },  
]

const TabExampleBasic = () => <Tab panes={panes} />

return (      
  <React.Fragment>
    <TabExampleBasic/>
  </React.Fragment>
)

}

export default DivisionsPanes;