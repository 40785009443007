import Api from 'lib/api'

const getAll = (payload) => {
  return dispatch => {
    Api.get('/leagues', payload, (err, res) => {
      if(err) return console.log('err', err)

      dispatch({
        type: 'SAVE_LEAGUES',
        payload: res
      })
    })
  }
}

export default getAll
