import React from 'react'
import styled from 'styled-components'

const StyledButton = styled.button`
  cursor: pointer;
  align-items: center;
  border: 0 none;
  justify-content: center;
  background: transparent;
  padding: 0;
  width: ${props => props.wid ? props.wid : 'inherit'};
  height: ${props => props.hei ? props.hei : 'inherit'};
  background-color: ${props => props.bg ? props.bg : 'transparent'};
`

const Clickable = (props) => (
  <StyledButton
    onClick={() => {
      props.click && props.click()
    }}
    {...props}
  >
    {props.children}
  </StyledButton>
)

export default Clickable
