import Api from 'lib/api'

const createDivisions = (payload) => {
  return dispatch => {
    Api.post('/seasons/createDivisions', payload, (err, res) => {
      if(err) return console.log('err', err)

      dispatch({
        type: 'CREATE_DIVISIONS',
        payload: {
          seasonId: payload.seasonId,
          divisions: res
        }
      })
    })
  }
}

export default createDivisions
