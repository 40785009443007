import Api from 'lib/api'

const makeDivisions = async ({ seasonId, data, persist = true, divisionSize = 6 }) => {
  //return dispatch => {
  console.log(data);
  const res = await Api.postAsync(`/seasons/${seasonId}/divisionplans?persist=${persist}&divisionSize=${divisionSize}`, data);
  return res.data;
  //}
}

export default makeDivisions;
