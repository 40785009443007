import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import allGamesPlayedByUser from "../graphql/queries/allGamesPlayedByUser";
import {
  Chart as ChartJS,
  // CategoryScale,
  LinearScale,
  TimeScale, //Import timescale instead of category for X axis
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import formatDate from '../lib/formatDate'; 
import DatePicker from 'react-datepicker';
import { Container, Button, Input, Icon} from "semantic-ui-react";
import allRatingAdjustmentsForUser from "../graphql/queries/allRatingAdjustmentsForUser";
import { isAfter, parseISO } from "date-fns";

const UserRatingView = ({ playerId }) => {

  ChartJS.register(
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    console.log(startDate)
  };
  
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const { loading, data, error, refetch } = useQuery(allGamesPlayedByUser, {
    variables: { playerId },
  });

  const { loading: loadingAdjustments, data: adjustmentsData } = useQuery(allRatingAdjustmentsForUser, {
    variables: { playerId },
  });  

  if (!data) return null;

  console.log("adjustmentsData ", adjustmentsData);

  console.log(data.gamePagination.items.length);

  const user = data.gamePagination.items[0].users.find(u => u.data._id === playerId);

  console.log(user);

  let elo = 750 * (user.data.leagueEntrySkillLevel ? (user.data.leagueEntrySkillLevel + 1) : (user.data.skillLevel + 1));
  // let elo = 750 * (2.66 + 1);
  // let time = user.createdAt;
  // console.log("User is ", user.name)
  // console.log("TIme is ", time)
  // const data = {};
  const labels = [user.data.createdAt];
  // const labels = [];
  const ratings = [elo];
  const glickoRatings = [elo];
  const comptPts = [0];
  const opponents = ["Initial Rating"];
  const results = [-1];
  const adjustments = adjustmentsData.ratingAdjustmentPagination.items;
  let index = 0;
  let currentAdjustmentIndex = -1;
  let adjustmentValue = 0;
  let prevRating = elo;
  for (const game of data.gamePagination.items) {    
    const isFirstUser = game.users[0].data._id === playerId;
    const key = 'glickoResults';
    const ratingkey = 'postMatchRatings';
    const ptsKey = 'ratingBonusPts';
    // console.log(isFirstUser ? game[key][0] : game[key][1]);
    const pts = isFirstUser ? game[ptsKey][0] : game[ptsKey][1];
    const rating = isFirstUser ? game[ratingkey][0] : game[ratingkey][1];    
    // console.log("game ", game._id);
    // if (currentAdjustmentIndex < 0 || isAfter(parseISO(game.createdAt), parseISO(adjustments[currentAdjustmentIndex].cutoff))) {
    //   if (currentAdjustmentIndex < adjustments.length - 1) {
    //     const nextAdjustment = adjustments[currentAdjustmentIndex + 1];
    //     if (isAfter(parseISO(game.createdAt), parseISO(nextAdjustment.cutoff))) {
    //       // next adjustment is in effect
    //       currentAdjustmentIndex ++;
    //       labels.push(nextAdjustment.cutoff);
    //       opponents.push(`Cut-off (${adjustments[currentAdjustmentIndex].value} pts)`);
    //       results.push(-1);
    //       // comptPts.push(pts);
    //       ratings.push(prevRating + adjustments[currentAdjustmentIndex].value);
    //       glickoRatings.push(prevRating);
    //     }
    //   } 
    //   // apply current adjustment value 
    //   if (currentAdjustmentIndex >= 0) {
    //     adjustmentValue = adjustments[currentAdjustmentIndex].value;
    //   }    
    // }
    // eloData.push(elo);
    console.log("adjustment ", adjustmentValue, rating, game._id);
    // eloData.push({ y: elo, x: time.toISOString() });
    labels.push(game.startTime);    
    index ++;
    // elo = elo + (isFirstUser ? game[key][0] : game[key][1]) + pts;
    console.log(game._id, game[key], game[ptsKey]);
    
    opponents.push(isFirstUser ? game.users[1].data.name : game.users[0].data.name)
    const isWinner = game.winnerId === playerId;
    results.push(+ isWinner);

    // time = game.startTime;
    comptPts.push(pts);
    prevRating = rating;
    // ratings.push(elo);
    // ratings.push(rating + pts);
    ratings.push(rating + pts);
    glickoRatings.push(rating);
    // labels.push();
  }

  const chartTitle = startDate && endDate ?
  `${user.data.name}'s Rating Over Time | ${formatDate(startDate)} - ${formatDate(endDate)}`:
  `${user.data.name}'s Rating Over Time`;
  
  const filteredLabels = [];
  const filteredRatings = [];
  const filteredCompPts = [];
  const filteredOpponents = [];
  const filteredResults = [];
  const filteredGlicko = [];

  console.log("labels ", labels);
  console.log("ratings ", ratings);
  console.log("opponents ", opponents);
  console.log("results ", results);

  if (startDate && endDate) {
    labels.forEach((label, index) => {
      const date = new Date(label);
      if (date >= startDate && date <= endDate) {
        filteredLabels.push(label);
        filteredRatings.push(ratings[index]);
        filteredCompPts.push(comptPts[index]);
        filteredOpponents.push(opponents[index]);
        filteredResults.push(results[index]);
        filteredGlicko.push(glickoRatings[index]);
      }
    });
  }

  const opponentsData = startDate && endDate ? filteredOpponents : opponents;
  const resultsData = startDate && endDate ? filteredResults : results;
  const labelsData = startDate && endDate ? filteredLabels : labels;

  const gameData =  {
    // datasets: [
    //   {
    //       label: 'Win all matches',
    //       data: [
    //         { y: 2598.6, x: '2018-08-17T17:53:29.000Z' },
    //       ],
    //       borderColor: "#00d885",
    //       backgroundColor: "#00d885",
    //   }
    // ]

    // labels: [1,2,3,4,5,6,7],
    // labels: data.gamePagination.items.map((game, index) => index),
    labels: labelsData,
    datasets: [{
      label: "Spin Rating",
      // data: data.gamePagination.items.map(game => game.glickoResults[0]),
      data: startDate && endDate ? filteredRatings : ratings,
      fill: false,
      radius: labelsData.map((label, index) => opponentsData[index].includes("Cut-off") ? 5 : 3),
      // borderColor: labels.map((label, index) => index % 2 === 0 ? 'rgb(75, 192, 192)': 'rgb(255, 0, 0)'),
      borderColor: 'rgb(75, 192, 192)',
      backgroundColor: labelsData.map((label, index) => opponentsData[index].includes("Cut-off") ? 'rgb(160, 160, 160)' : 'rgb(75, 192, 192)'),
      // borderWidth: 1,
      tension: 0.1
    },
    // {
    //   label: "Glicko",
    //   // data: data.gamePagination.items.map(game => game.glickoResults[0]),
    //   // data: startDate && endDate ? filteredRatings : ratings,
    //   data: startDate && endDate ? filteredGlicko : glickoRatings,
    //   fill: false,
    //   // radius: 5,
    //   borderColor: 'rgb(255, 0, 0)',
    //   backgroundColor: 'rgb(255, 0, 0)',      
    //   // borderWidth: 1,
    //   tension: 0.1
    // }    
  ]
  }

  return <>
    <Line 
        data={gameData} 
        options= {{   
          plugins: {
            tooltip: {
              callbacks: {
                beforeTitle: function (context) {
                  // console.log
                  switch (resultsData[context[0].dataIndex]) {
                    case 1:
                      return `Defeated ${opponentsData[context[0].dataIndex]}`;
                    case 0:
                      return `Lost to ${opponentsData[context[0].dataIndex]}`;            
                    default:
                      return `${opponentsData[context[0].dataIndex]}`;
                  }                
                  // return `${opponents[context[0].dataIndex]}`;
                }
              }
            },
            // legend: { display: false },
            title: {
              display: true,
              text: chartTitle,
              position: "top"
            }
          },          
          scales: {
            x: {
              type: 'time',
              time: {
                  unit: 'month'
              }
            }        
          },
          // responsive: false,
        }} 
      />
      <Container style = {{width: '100%', padding: '25px'}}>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <div style={{marginRight: '25px'}}>
            <label htmlFor="startDate" style={{marginRight: '10px'}}>Start Date:</label>
            <DatePicker
              id="startDate"
              dateFormat="MMM d, yyyy"
              selected={startDate}
              maxDate={endDate ? endDate : new Date()}
              onChange={handleStartDateChange}
              customInput={<Input icon={<Icon name="calendar" />} />}
            />
          </div>
          <div>
            <label htmlFor="endDate" style={{marginRight: '10px'}}>End Date:</label>
            <DatePicker
              id="endDate"
              dateFormat="MMM d, yyyy"
              maxDate={new Date()}
              minDate={startDate}
              selected={endDate}
              onChange={handleEndDateChange}
              customInput={<Input icon={<Icon name="calendar" />} />}
            />
          </div>
          {(startDate && endDate) && <div style={{marginLeft: '25px'}}>
            <Button
              content='Remove Date Filter'
              onClick={() => {
                setStartDate(null);
                setEndDate(null);
              }}
            />
          </div>}
        </div>
      </Container>
    </>;


  // return  <Chart
            // type="line"
            // options={}
            // options= {{     
            //   scales: {
            //     x: {
            //       type: 'time',
            //       time: {
            //           unit: 'month'
            //       }
            //     }        
            //   },
            //   responsive: false,
            // }}        
            // data={gameData}
            // {...props}
          // />  
}

export default UserRatingView;