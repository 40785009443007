import React from 'react'
import { withRouter } from 'react-router-dom'
import { Button } from 'components'
import Api from 'lib/api'
import { connect } from 'react-redux'

import { setUser } from 'store/actions/user'

const SignupButton = (props) => (
  <Button
    text='Sign up'
    click={() => {
      Api.post(
        '/users/signup',
        { email: props.email, password: props.password },
        (err, res) => {
          if(err) {
            return console.warn('err', err)
          }
          // set JWT to device storage
          localStorage.setItem('token', res.token)

          props.setUser(res.user)
        }
      )
    }}
  />
)

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    setUser: user => {
      dispatch(setUser(user))
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SignupButton)
)
