import { gql } from '@apollo/client';

const playerReadjustments = gql`
  query PlayerProgressLog($playerId: MongoID) {
    playerProgressLog(
      filter: { userId: $playerId },
    ) {
      _id
      userId
      previousValue
      finalOverride
    }
  }
`;

export default playerReadjustments;
