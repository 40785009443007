import styled, { css } from 'styled-components'

const Avatar = styled.img`
  border-radius: 50%;
  align-self: baseline;
  ${props => props.absolute &&
    css`
      position: absolute;
      left: 20px;
    `
  };
  margin: ${props => props.marg && props.marg};
  ${props => props.size === 'tiny'
    ? (
      css`
        width: 22px;
        height: 22px;
        min-width: 22px;
      `
    ) : (
      props.size === 'big'
      ? (
        css `
          width: 61px;
          height: 61px;
          min-width: 61px;
        `
      ) : (
        css `
          width: 30px;
          height: 30px;
          min-width: 30px;
        `
      )
    )
  }

`

export default Avatar
